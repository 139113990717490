import { Add, AddCircle } from '@mui/icons-material';
import { Divider, Grow, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { TramitesContext } from '../pages/TramitesPage';
import { TramiteComponent } from './TramiteComponent';

export const TablaTramitesComponent = () => {
 const {modalTramites, setModalTramites, tramites, obtenerTramites} = useContext(TramitesContext);

 
  useEffect(() => {
    obtenerTramites();
  }, [])
  


  return (
    <div
    style={{
      display:'flex',
      flexDirection:'column',
      flex:1,
        color:'black',
        marginTop:10,
        marginLeft:40,
        marginRight:20,
        overflowY:'auto'
    }}
>
  <div
    style={{
      display:'flex',
      flexDirection:'row',
      flex:1,
      alignItems:'center',
      maxHeight:70,
      justifyContent:'space-between'
    }}
  >
    <div
      style={{
        display:'flex',
        flexDirection:'row',
        justifyContent:'space-between',
        flex:1
      }}
    >
      <span
        style={{
          fontSize:40,
          fontWeight:'bold'
        }}
      >
        Trámites
      </span>
      <div
        onClick={() => setModalTramites(true)}
        style={{
          fontSize:40,
          fontWeight:'bold',
          display:'flex',
          flexDirection:'row',
          alignItems:'center'
        }}
      >
        <AddCircle style={{color:'green'}} />
        <Typography>
          Agregar
        </Typography>
      </div>
    </div>
  </div>
  <div
    style={{
      display:'flex',
      flexDirection:'row',
      fontWeight:500,
      fontSize:13,
      backgroundColor:'white',
      padding:10
    }}
  >
    <div
      style={{
        display:'flex',
        flex:5
      }}
    >
      Id
    </div>
    <div
      style={{
        display:'flex',
        flex:10
      }}
    >
      Dependencia
    </div>
    <div
      style={{
        display:'flex',
        flex:30
      }}
    >
      Trámite
    </div>
    <div
      style={{
        display:'flex',
        flex:15
      }}
    >
      Domicilio
    </div>
    <div
      style={{
        display:'flex',
        flex:30
      }}
    >
      Requisitos
    </div>
    <div
      style={{
        display:'flex',
        flex:20
      }}
    >
      Horario Atención
    </div>
    <div
      style={{
        display:'flex',
        flex:10
      }}
    >
      Teléfono
    </div>
    <div
      style={{
        display:'flex',
        flex:15
      }}
    >
      Costo
    </div>
    <div
      style={{
        display:'flex',
        flex:3
      }}
    >
      
    </div>
    <div
      style={{
        display:'flex',
        flex:3
      }}
    >
      
    </div>

  </div>
  <div
    style={{
      display:'flex',
      flexDirection:'column',
      maxHeight:'70vh',
      overflowY:'auto'
    }}
  >
    {
      tramites?.map(tramite => (
        <>
          <TramiteComponent tramite={tramite} />
          <Divider />
        </>
      ))
    }
  </div>
  </div>
  )
}
