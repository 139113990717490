import { Delete, Edit } from '@mui/icons-material';
import React, { useContext, useState } from 'react'
import { TramitesContext } from '../pages/TramitesPage';
import { ModalEliminaTramite } from './ModalEliminaTramite';

export const TramiteComponent = ({tramite}) => {
    const {tramiteSeleccionado, setTramiteSeleccionado, editandoTramite, setEditandoTramite, setModalTramites} = useContext(TramitesContext);
    const [modalEliminarTramite, setModalEliminarTramite] = useState(false);
  
    const eliminarTramite = () => {
      setModalEliminarTramite(true);
    }

    const editarTramite = () => {
      setEditandoTramite(true);
      setModalTramites(true);
    }

  return (
    <>
    <div
    onClick={() => setTramiteSeleccionado(tramite)}
    style={{
      display:'flex',
      flexDirection:'row',
      fontWeight:'normal',
      fontSize:13,
      padding:10,
      boxShadow: (tramiteSeleccionado?.IdTramite==tramite.IdTramite)  ? '1px 2px 9px #0f0f0f':'',
      borderRadius:10
    }}
  >
    <div
      style={{
        display:'flex',
        flex:5
      }}
    >
      {tramite.IdTramite}
    </div>
    <div
      style={{
        display:'flex',
        flex:10
      }}
    >
      {tramite.Dependencia}
    </div>
    <div
      style={{
        display:'flex',
        flex:30
      }}
    >
      {tramite.NombreTramite}
    </div>
    <div
      style={{
        display:'flex',
        flex:15,
//        color:tramite.Color
      }}
    >
      {tramite.Domicilio}
    </div>
    <div
      style={{
        display:'flex',
        flex:30
      }}
    >
      {tramite.Requisitos}
    </div>
    <div
      style={{
        display:'flex',
        flex:20
      }}
    >
      {tramite.HorarioAtencion}
    </div>
    <div
      style={{
        display:'flex',
        flex:10
      }}
    >
      {tramite.TelefonoInformes}
    </div>
    <div
      style={{
        display:'flex',
        flex:15
      }}
    >
      {tramite.Costo}
    </div>
    <div
      onClick={() => editarTramite()}
      style={{
        display:'flex',
        flex:3
      }}
    >
      <Edit style={{color:'green'}} />
    </div>
    <div
    onClick={() => eliminarTramite()}
      style={{
        display:'flex',
        flex:3
      }}
    >
      <Delete  style={{color:'red'}} />
    </div>

  </div>
  <ModalEliminaTramite modalEliminarTramite={modalEliminarTramite} setModalEliminarTramite={setModalEliminarTramite} />
  </>
  )
}
