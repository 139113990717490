import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { EliminarTramite } from '../helpers/TramitesHelper';
import { TramitesContext } from '../pages/TramitesPage';

export const ModalEliminaTramite = ({modalEliminarTramite, setModalEliminarTramite}) => {
    const {tramiteSeleccionado, obtenerTramites} = React.useContext(TramitesContext);
  const handleClose = () => {
    setModalEliminarTramite(false);
  };

  const eliminarTramite = () => {
    EliminarTramite(tramiteSeleccionado).then(respuesta => {
        obtenerTramites();
        setModalEliminarTramite(false)

    });
  }

  return (
    <div>
      <Dialog
        open={modalEliminarTramite}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Eliminar Trámite "{tramiteSeleccionado?.NombreTramite}"
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Está seguro(a) de eliminar el trámite? Ya no aparecerá para consultas en whatsapp ni en la App Móvil
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{color:'red'}} onClick={() => eliminarTramite()}>Eliminar</Button>
          <Button onClick={handleClose} autoFocus>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}