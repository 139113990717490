import React, { useContext } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Typography } from '@mui/material';
import { useState } from 'react';
import { useEffect } from 'react';
import { AuthContext } from '../context/AuthContext';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

//ChartJS.defaults.plugins.legend.labels.font.size=14;


export const options = {
  responsive: false,
  maintainAspectRatio:true,
  borderRadius: {
    top:10
  },
  scales: {
    xAxes: [{
        labels: {
            fontSize: 20
        }
    }]
},
  plugins: {
    legend: {
      position: 'top',
      labels: {
        font: {
            size:20
        }
      }
    },
    title: {
      display: false,
      text: 'Chart.js Bar Chart',
    },
  },
};

const labels = ['3 Jacales','El Millon','Jesús Carranza','Álamos de San Lorenzo', 'Bellavista'];

// export const data = ;

export function GraficaColonias() {
  const {usuario} = useContext(AuthContext)
  const [datosGrafica, setDatosGrafica] = useState();
  const [data2, setData2] = useState({
    labels,
    datasets: [
      {
        label: 'Baches',
        data: [100,150],
        backgroundColor: '#2D794E',
        borderRadius:30
      },
      {
        label: 'Luminaria',
        data: [120,45],
        backgroundColor: '#82DDAA',
        borderRadius:30
      },
    ],
  });
  useEffect(() => {
    obtenerDatos();
  }, [])
  

  const obtenerDatos = () => {
    fetch(process.env.REACT_APP_API_URL+'/api.reportesp.php?funcion=totalesColonia&idUsuario='+usuario.Usuarios_IdUsuario)
    .then(response => response.json())
    .then(datos => {
      console.log('datos para grafica por colonia', datos);
      setDatosGrafica(datos);
    })
    .catch(error => {
      console.error('Ocurrio un error', error);
    });
  }

  useEffect(() => {
    console.log('actualizando grafica', datosGrafica)
    setData2({
      labels:datosGrafica?.colonias?.map(dato => {return dato.Colonia}),
      datasets: datosGrafica?.datasets,
    }

    )
    // data.labels = datosGrafica?.map((dato) => { return dato.Colonia});
    
    // console.log(data.labels);
  }, [datosGrafica])
  
if(data2?.labels) {
  return (
    <div
        style={{
            display:'flex',
            flexDirection:'column',
         //   maxHeight:'45vh'
        }}
    >
        <div
        style={{
        display:'flex',
        flexDirection:'row',
        }}
    >
        <div
        style={{
            display:'flex',
            flexDirection:'column',
            padding:0, margin:0
        }}
        >
            <div
                style={{
                    display:'flex',
                    flexDirection:'row',
                    padding:0,margin:0
                }}
            >
                <Typography style={{fontSize:24, fontWeight:'bold', marginBottom:0, minHeight:0, height:20}}>Colonias</Typography>
                <Typography style={{fontSize:28, fontWeight:'bold', marginLeft:20, marginTop:0, minHeight:0}}>744</Typography>

            </div>
        <Typography style={{fontSize:12, color:'#656565'}}>Top 5 Reportes</Typography>
        </div>
    </div>
    <div
        style={{
            minWidth:'100%',
            display:'flex',
            flexDirection:'row',
            justifyContent:'center',
            marginTop:20
        }}
    >
        <Bar width={600} height={300}  options={options} data={data2} />

    </div>
    </div>
  )
      }
}
