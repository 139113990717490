import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { SketchPicker } from 'react-color';
import { GuardarDatosPolygono, ObtenerSectores } from '../helpers/SectoresHelper';
import { GuardarTramite } from '../helpers/TramitesHelper';
import { SectoresContext } from '../pages/SectoresPage';
import { TramitesContext } from '../pages/TramitesPage';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
export const ModalSectorComponent = () => {
  const {modalSector, setModalSector, obtenerSectores} = useContext(SectoresContext);
  const [errorGuardado, setErrorGuardado] = useState(false);
  const [datosSector, setDatosSector] = useState();
  const [color, setColor] = useState();
  const [descripcion, setDescripcion] = useState();
  const [idSector, setIdSector] = useState(0);
    const handleClose = () => {
        setModalSector(false);
    }

    useEffect(() => {
      console.log('cambio modal tramites', modalSector)
    }, [modalSector])


    useEffect(() => {
      console.log('datos sector', datosSector)
    }, [datosSector])
    
    useEffect(() => {
      console.log('el color cambio', color);
    }, [color])


    
 
    

    const handleSubmit =  (event) => {
        event.preventDefault();
        //refForm.current.isValidate();
        GuardarDatosPolygono(descripcion,color,idSector).then(respuesta => {
            console.log('respuesta de guardado en modal guardar sector', respuesta);
            if(respuesta!=1) {
                setErrorGuardado(true);
            } else{
                setErrorGuardado(false);
                setDatosSector({});
                obtenerSectores();
                handleClose();

            }
        })
    }

    const actualizaDatos = (dato, valor) => {
      setDatosSector({
          ...datosSector,
          [dato]:valor
      })
    }


  return (
      <div
        style={{
            minWidth:600
        }}
    >
     <Dialog open={modalSector} onClose={handleClose}>
        <form onSubmit={handleSubmit} >
        <DialogTitle>Sector</DialogTitle>
        <DialogContent
            style={{minWidth:600}}
        >
          <TextField
            margin="dense"
            id="name"
            InputLabelProps={{ shrink: true }}
            label="Sector"
            type="text"
            fullWidth
            variant="standard"
            required={true}
            onChange={(e) => setDescripcion(e.target.value)}
            value={descripcion}
          />
          <Typography>Color de fondo</Typography>
          <SketchPicker 
            color={color}
            onChangeComplete={(e) => setColor(e.hex)}
          />
          {
            errorGuardado && (
              <Typography
                style={{
                    fontSize:12,
                    color:'red'
                }}
              >
                Error al guardar
            </Typography>
            )
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button type='submit' >Guardar</Button>
        </DialogActions>
        </form>
      </Dialog>
    </div>
  )
}
