import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { GuardarTramite } from '../helpers/TramitesHelper';
import { TramitesContext } from '../pages/TramitesPage';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
export const ModalTamiteComponent = () => {
  const {tramiteSeleccionado, modalTramites, setModalTramites, tramites, obtenerTramites, dependencias, opcionesDependencias, editandoTramite, setEditandoTramite} = useContext(TramitesContext);
  const [errorGuardado, setErrorGuardado] = useState(false);
  const [tramite, setTramite] = useState();
  const [datosTramite, setDatosTramite] = useState();
  const [value, setValue] = useState();
    const handleClose = () => {
        setEditandoTramite(false);
        setModalTramites(false);
        setDatosTramite();
        setValue();
    }
console.log('opciones dependencias', opcionesDependencias)
    useEffect(() => {
      console.log('cambio modal tramites', modalTramites)
    }, [modalTramites])

    useEffect(() => {
      console.log('tramiteSeleccionado', tramiteSeleccionado)
      if(editandoTramite) {
        const index = opcionesDependencias.map(opcion => opcion.id).indexOf(tramiteSeleccionado?.IdDependencia)
        setValue((index))
        setDatosTramite({
          idTramite:tramiteSeleccionado?.IdTramite,
          tramite:tramiteSeleccionado?.NombreTramite,
          idDependencia:tramiteSeleccionado?.IdDependencia,
          dependencia:tramiteSeleccionado?.Dependencia,
          domicilio:tramiteSeleccionado?.Domicilio,
          requisitos:tramiteSeleccionado?.Requisitos,
          horario:tramiteSeleccionado?.HorarioAtencion,
          telefono:tramiteSeleccionado?.TelefonoInformes,
          costo:tramiteSeleccionado?.Costo
        })
      }
    }, [editandoTramite, tramiteSeleccionado])

    useEffect(() => {
      console.log('datos tramite', datosTramite)
    }, [datosTramite])
    
    
    useEffect(() => {
      console.log('dependencia seleccionada', value)
    }, [value])
    

    const handleSubmit =  (event) => {
        event.preventDefault();
        //refForm.current.isValidate();
        GuardarTramite(datosTramite).then(respuesta => {
            console.log('respuesta de guardado en modal llamando funcion guardar tramite', respuesta);
            if(respuesta!=1) {
                setErrorGuardado(true);
            } else{
                setErrorGuardado(false);
                setDatosTramite({});
                setEditandoTramite(false);
                obtenerTramites();
                handleClose();

            }
        })
    }

    const actualizaDatos = (dato, valor) => {
      setDatosTramite({
          ...datosTramite,
          [dato]:valor
      })
    }


  return (
      <div
        style={{
            minWidth:600
        }}
    >
     <Dialog open={modalTramites} onClose={handleClose}>
        <form onSubmit={handleSubmit} >
        <DialogTitle>Trámite</DialogTitle>
        <DialogContent
            style={{minWidth:600}}
        >
          <Autocomplete
            onChange={(event, newValue) => {actualizaDatos('idDependencia', newValue)}}
            // disablePortal
            InputLabelProps={{ shrink: true }}
            id="combo-box-demo"
            options={opcionesDependencias}
            sx={{ width: '100%', zIndex:9999999 }}
            style={{zIndex:999999}}
            renderInput={(params) => <TextField value={datosTramite?.dependencia}  InputLabelProps={{ shrink: true }}  autoFocus style={{zIndex:999999, backgroundColor:'white', marginTop:5}} {...params} label="Dependencia" />}
          />
          <TextField
            margin="dense"
            id="name"
            InputLabelProps={{ shrink: true }}
            label="Nombre del Trámite"
            type="text"
            fullWidth
            variant="standard"
            required={true}
            onChange={(e) => actualizaDatos('tramite', e.target.value)}
            value={datosTramite?.tramite}
          />
          <TextField
            InputLabelProps={{ shrink: true }}
            autoFocus
            margin="dense"
            id="name"
            name="txtTipoActivo"
            label="Domicilio"
            type="text"
            fullWidth
            variant="standard"
            multiline
            rows={4}
            //required={true}
            onChange={(e) => actualizaDatos('domicilio', e.target.value)}
            value={datosTramite?.domicilio}
          />
          <TextField
          InputLabelProps={{ shrink: true }}
            autoFocus
            margin="dense"
            id="name"
            name="txtTipoActivo"
            label="Requisitos"
            multiline={true}
            rows={4}
            type="text"
            fullWidth
            variant="standard"
            //required={true}
            onChange={(e) => actualizaDatos('requisitos', e.target.value)}
            value={datosTramite?.requisitos}
          />
          <TextField
          InputLabelProps={{ shrink: true }}
            autoFocus
            margin="dense"
            id="name"
            name="txtTipoActivo"
            label="Horario de Atención"
            multiline={false}
            rows={4}
            type="text"
            fullWidth
            variant="standard"
            //required={true}
            onChange={(e) => actualizaDatos('horario', e.target.value)}
            value={datosTramite?.horario}
          />
          <TextField
          InputLabelProps={{ shrink: true }}
            autoFocus
            margin="dense"
            id="name"
            name="txtTipoActivo"
            label="Teléfono"
            multiline={false}
            rows={4}
            type="text"
            fullWidth
            variant="standard"
            //required={true}
            onChange={(e) => actualizaDatos('telefono', e.target.value)}
            value={datosTramite?.telefono}
          />
          <TextField
          InputLabelProps={{ shrink: true }}
            autoFocus
            margin="dense"
            id="name"
            name="txtTipoActivo"
            label="Costo"
            multiline={true}
            rows={4}
            type="text"
            fullWidth
            variant="standard"
            //required={true}
            onChange={(e) => actualizaDatos('costo', e.target.value)}
            value={datosTramite?.costo}
          />
          {
            errorGuardado && (
              <Typography
                style={{
                    fontSize:12,
                    color:'red'
                }}
              >
                Error al guardar
            </Typography>
            )
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button type='submit' >Guardar</Button>
        </DialogActions>
        </form>
      </Dialog>
    </div>
  )
}
