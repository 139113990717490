export const ObtenerSectores = async() => {
    return new Promise((resolve, reject) => {

        const data = new FormData();
       // data.append('orden','TipoInstalacion asc')
         fetch(process.env.REACT_APP_API_URL+'/sectores.php?funcion=sectores', {
            method:'POST',
            body:data
        })
        .then(response => response.json())
        .then(datos => resolve(datos))
    })
    .then((datos) => {
        return datos
    })
    .catch(error => {
        console.warn(error);
    })
}

export const GuardarPolygono = async(idSector, coordenadas) => {
    return new Promise((resolve, reject) => {

        const data = new FormData();
        data.append('idSector',idSector);
        data.append('coordenadas', JSON.stringify(coordenadas))
         fetch(process.env.REACT_APP_API_URL+'/sectores.php?funcion=guardarPolygono', {
            method:'POST',
            body:data
        })
        .then(response => response.json())
        .then(datos => resolve(datos))
    })
    .then((datos) => {
        return datos
    })
    .catch(error => {
        console.warn(error);
    })
}


export const GuardarDatosPolygono = async(descripcion,color, idSector) => {
    return new Promise((resolve, reject) => {

        const data = new FormData();
        data.append('idSector',idSector);
        data.append('descripcion', descripcion);
        data.append('color',color)
         fetch(process.env.REACT_APP_API_URL+'/sectores.php?funcion=guardarDatosPolygono', {
            method:'POST',
            body:data
        })
        .then(response => response.json())
        .then(datos => resolve(datos))
    })
    .then((datos) => {
        return datos
    })
    .catch(error => {
        console.warn(error);
    })
}

export const EliminarSector = async(idSector) => {
    return new Promise((resolve, reject) => {

        const data = new FormData();
        data.append('idSector',idSector);
         fetch(process.env.REACT_APP_API_URL+'/sectores.php?funcion=eliminarSector', {
            method:'POST',
            body:data
        })
        .then(response => response.json())
        .then(datos => resolve(datos))
    })
    .then((datos) => {
        return datos
    })
    .catch(error => {
        console.warn(error);
    })
}





