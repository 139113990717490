import { Delete, Edit } from '@mui/icons-material'
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { SectoresContext } from '../pages/SectoresPage'

export const TablaSectoresComponent = ({sectores}) => {
    const {setModalSector, modalEliminaSector, setModalEliminaSector, setSectorElimina} = useContext(SectoresContext)
    console.log('sectores recibidos en tabla', sectores)

    const eliminarSector = (idSector => {

    })

  return (
    <div
    style={{
        display:'flex',
        flexDirection:'column',
        flex:1,
        maxWidth:'45%',
        marginLeft:20
    }}
>
    <div
        style={{
            marginBottom:10,
            marginTop:10
        }}
    >
        <Button
            onClick={() => setModalSector(true)}
            variant="contained"
            style={{
                backgroundColor:'#642644'

            }}
        >
            Agregar
        </Button>
    </div>
    <TableContainer
        style={{
            display:'flex',
            flex:1,
            maxHeight:'70vh'
            
        }}
    >
        <Table stickyHeader>
            <TableHead>
                <TableRow>
                    <TableCell>Sector</TableCell>
                    <TableCell>Color</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    sectores?.map(sector => (

                    <TableRow>
                        <TableCell>{sector.descripcion}</TableCell>
                        <TableCell>
                            <Typography
                                style={{
                                    color:sector.color
                                }}
                            >
                                {sector.color}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Edit onClick={() => {setModalEliminaSector(true)}} style={{color:"green"}} />
                            <Delete onClick={() => {setSectorElimina(sector); setModalEliminaSector(true)}} style={{color:"red"}} />
                        </TableCell>
                    </TableRow>
                ))
                }
            </TableBody>
        </Table>
    </TableContainer>
    </div>
  )
}
