import React, { createContext, useState } from 'react'
import { RespuestasBot } from '../components/RespuestasBot';
import { TablaServiciosComponent } from '../components/TablaServiciosComponent'

export const ServiciosContext = createContext();

export const ServiciosPage = () => {
    const [servicioSeleccionado, setServicioSeleccionado] = useState();
  return (
    <ServiciosContext.Provider value={{servicioSeleccionado, setServicioSeleccionado}}>
    <div
        style={{
            display:'flex',
            flexDirection:'row',
            flex:1
        }}
    >
        <div
            style={{
                display:'flex',
                flexDirction:'column',
                flex:50,
                backgroundColor:'transparent'
            }}  
        >
            <TablaServiciosComponent />
        </div>
        <div
            style={{
                display:'flex',
                flexDirection:'column',
                flex:50,
                padding:10,
                backgroundColor:'lightgray'
            }}  
        >
            <RespuestasBot />
        </div>

    </div>
    </ServiciosContext.Provider>
  )
}
