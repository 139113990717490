import { Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { ObtenerTextosBot } from '../helpers/ServiciosHelper';
import { ServiciosContext } from '../pages/ServiciosPage'
import { TextoBot } from './TextoBot';

export const RespuestasBot = () => {
    const {servicioSeleccionado} = useContext(ServiciosContext);
    const [textosBot, setTextosBot] = useState();
    const [textoSeleccionado, setTextoSeleccionado] = useState();

    const obtenerTextosBot = () => {
        ObtenerTextosBot(servicioSeleccionado?.IdTipoReporte).then(datos=>setTextosBot(datos));
      }

      useEffect(() => {
        console.log('textos del bot', textosBot);
        
      }, [textosBot])

    useEffect(() => {
        console.log('servicio seleccionado bot', servicioSeleccionado)
        setTextoSeleccionado({});
        obtenerTextosBot();
    }, [servicioSeleccionado])


          

  return (
    <div
    style={{
      display:'flex',
      flexDirection:'column',
      flex:1,
        color:'black',
        marginTop:10,
        marginLeft:40,
        marginRight:20
    }}
>
  <div
    style={{
      display:'flex',
      flexDirection:'row',
      flex:1,
      alignItems:'center',
      maxHeight:70,
      justifyContent:'space-between'
    }}
  >
    <div

    >
      <span
        style={{
          fontSize:25,
          fontWeight:'bold'
        }}
      >
        Respuestas Celia (Whatsapp)
      </span>
    </div>
  </div>
  <div
    style={{
        display:'flex',
        flex:1,
        flexDirection:'row',
        backgroundColor:'transparent'
    }}
  >
    <div
        style={{
            display:'flex',
            flexDirection:'column',
            flex:50,
            alignItems:'center'
        }}
    >
        <div
            onClick={() => {
              setTextoSeleccionado({id:textosBot?.opcionMenu?.IdOpcionMenu, texto:textosBot?.opcionMenu?.Opcion, tabla:'opcionesmenu'})
            }}
            style={{
                display:'flex',
                flexDirection:'row',
                backgroundColor:'white',
                borderRadius:10,
                maxWidth:250,
                minWidth:150,
                minHeight:50,
                justifyContent:'center',
                alignItems:'center',
                fontSize:12
            }}
        >
          <Typography
            style={{fontSize:12}}
          >
            Nombre de servicio
            </Typography>
        </div>
        <div
            onClick={() => {
              setTextoSeleccionado({id:textosBot.respuestaDonde.IdRespuesta, texto:textosBot.respuestaDonde.Mensaje, tabla:'respuestas'})
            }}

            style={{
                display:'flex',
                flexDirection:'row',
                backgroundColor:'white',
                borderRadius:10,
                maxWidth:250,
                minWidth:150,
                minHeight:50,
                justifyContent:'center',
                alignItems:'center',
                marginTop:30
            }}
        >
          <Typography
            style={{fontSize:12}}
          >
            ¿Dónde se encuentra?
            </Typography>
        </div>
        <div
            style={{
                display:'flex',
                flexDirection:'row',
                backgroundColor:'lightgray',
                borderRadius:10,
                maxWidth:250,
                minWidth:150,
                minHeight:50,
                justifyContent:'center',
                alignItems:'space-around',
                marginTop:30
            }}
        >
          <div
            onClick={() => {
              setTextoSeleccionado({id:textosBot.primerOpcionUbicacion.IdOpcionMenu, texto:textosBot.primerOpcionUbicacion.Opcion, tabla:'opcionesmenu'})
            }}

            style={{
              display:'flex',
              flexDirection:'column',
              flex:50,
              backgroundColor:'white',
              borderRadius:10,
              maxWidth:250,
              minWidth:150,
              minHeight:50,
              justifyContent:'center',
              alignItems:'center',
          }}
          >
          <Typography
            style={{fontSize:12}}
          >
                En mi ubicación
              </Typography>
          </div>
          <div
            onClick={() => {
              setTextoSeleccionado({id:textosBot.segundaOpcionUbicacion.IdOpcionMenu, texto:textosBot.segundaOpcionUbicacion.Opcion, tabla:'opcionesmenu'})
            }}

            style={{
              display:'flex',
              flexDirection:'column',
              flex:50,
              backgroundColor:'white',
              borderRadius:10,
              maxWidth:250,
              minWidth:150,
              minHeight:50,
              justifyContent:'center',
              alignItems:'center',
              marginLeft:40
          }}
          >
          <Typography
            style={{fontSize:12}}
          >
                Otra ubicación
              </Typography>
          </div>
        </div>
        <div
            style={{
                display:'flex',
                flexDirection:'row',
                backgroundColor:'lightgray',
                borderRadius:10,
                maxWidth:250,
                minWidth:150,
                minHeight:50,
                justifyContent:'center',
                alignItems:'space-around',
                marginTop:30
            }}
        >
          <div
            onClick={() => {
              setTextoSeleccionado({id:textosBot.respuestaComparteUbicacion.IdRespuesta, texto:textosBot.respuestaComparteUbicacion.Mensaje, tabla:'respuestas'})
            }}

            style={{
              display:'flex',
              flexDirection:'column',
              flex:50,
              backgroundColor:'white',
              borderRadius:10,
              maxWidth:250,
              minWidth:150,
              minHeight:50,
              justifyContent:'center',
              alignItems:'center',
          }}
          >
          <Typography
            style={{fontSize:12}}
          >
                Comparte tu ubicación
              </Typography>
          </div>
          <div
            onClick={() => {
              setTextoSeleccionado({id:textosBot.respuestaEscribeDireccion.IdRespuesta, texto:textosBot.respuestaEscribeDireccion.Mensaje, tabla:'respuestas'})
            }}

            style={{
              display:'flex',
              flexDirection:'column',
              flex:50,
              backgroundColor:'white',
              borderRadius:10,
              maxWidth:250,
              minWidth:150,
              minHeight:50,
              justifyContent:'center',
              alignItems:'center',
              marginLeft:40
          }}
          >
          <Typography
            style={{fontSize:12}}
          >
                Escribe la dirección
              </Typography>
          </div>
        </div>
        <div
            onClick={() => {
              setTextoSeleccionado({id:textosBot.respuestaComparteFotografia.IdRespuesta, texto:textosBot.respuestaComparteFotografia.Mensaje, tabla:'respuestas'})
            }}

            style={{
                display:'flex',
                flexDirection:'row',
                backgroundColor:'white',
                borderRadius:10,
                maxWidth:250,
                minWidth:150,
                minHeight:50,
                justifyContent:'center',
                alignItems:'center',
                marginTop:30
            }}
        >
          <Typography
            style={{fontSize:12}}
          >
            Comparte una fotografía
            </Typography>
        </div>

    </div>
    <div
        style={{
            display:'flex',
            flexDirection:'column',
            flex:50,
            alignItems:'flex-end',
            backgroundColor:'transparent'
        }}
    >
        <TextoBot textosBot={textosBot} textoSeleccionado={textoSeleccionado} setTextoSeleccionado={setTextoSeleccionado} />
    </div>
  </div>
  </div>
  )
}
