import React, { createRef, useContext, useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Button, Typography } from '@mui/material';
import Punto from '@mui/icons-material/Circle';
import Punto1 from '@mui/icons-material/CircleOutlined';
import FlechaArriba from '@mui/icons-material/ArrowUpward';
import { DashboardContext } from '../pages/DashboardPage';
import DayJs from 'dayjs';
import 'dayjs/locale/es-mx'
DayJs.locale('es-mx');
ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.defaults.plugins.legend=false;
//ChartJS.defaults.plugins.width=150;
//ChartJS.defaults.plugins.height=150;
//ChartJS.defaults.Doughnut.plugins.

export const data = {
  labels: ['Resueltos', 'Proceso', 'Enviados'],
  datasets: [
    {
      label: 'Reportes',
      data: [12, 19, 3],
      options: {
        plugins: {
          legend: {
            display:false
          }
          
        },
        responsive:true,
        maintainAspectRatio:false
      },
      backgroundColor: [
        '#2D794E',
        '#82DDAA',
        '#FEFEFE',
      ],
      borderColor: [
        '#2D794E',
        '#82DDAA',
        '#82DDAA',
      ],
      borderWidth: 1,
      cutout: '85%'
    },
  ],
};
const chartReference = createRef();

export function GraficaBaches() {
  const datosDashboard = useContext(DashboardContext);
  const [numeroReportes, setNumeroReportes] = useState(datosDashboard?.datosDashboard[0]?.TotalReportesMesBaches);
  const [conteoMostrar, setConteoMostrar] = useState('mes');
  const [efectividad, setEfectividad] = useState(0);
  const [datos2, setDatos2] = useState({
    labels: ['Resueltos', 'Proceso', 'Enviados'],
    datasets: [
      {
        label: 'Reportes',
        data: [12, 19, 3],
        options: {
          plugins: {
            legend: {
              display:false
            }
            
          },
          responsive:true,
          maintainAspectRatio:false
        },
        backgroundColor: [
          '#2D794E',
          '#82DDAA',
          '#FEFEFE',
        ],
        borderColor: [
          '#2D794E',
          '#82DDAA',
          '#82DDAA',
        ],
        borderWidth: 1,
        cutout: '85%'
      },
    ],
  })
  const mes = DayJs(new Date()).format('MMMM');
  console.log(mes);

  useEffect(() => {
    console.log('cambio dashboard o tipo', datosDashboard,conteoMostrar)
    let reportesEnviados =0;
    let reportesResueltos = 0;
    let reportesProceso = 0;
    if(conteoMostrar==='mes') {
      reportesEnviados = parseInt(datosDashboard?.datosDashboard[0]?.TotalReportesMesBaches);
      reportesResueltos = parseInt(datosDashboard?.datosDashboard[0]?.TotalReportesMesBachesResueltos);
      reportesProceso = parseInt(datosDashboard?.datosDashboard[0]?.TotalReportesMesBachesProceso);
    }
    if(conteoMostrar==='historico') {
      reportesEnviados = parseInt(datosDashboard?.datosDashboard[0]?.TotalReportesBaches);
      reportesResueltos = parseInt(datosDashboard?.datosDashboard[0]?.TotalReportesBachesResueltos);
      reportesProceso = parseInt(datosDashboard?.datosDashboard[0]?.TotalReportesBachesProceso);
    }
    setEfectividad(((reportesResueltos/reportesEnviados)*100).toFixed(2));

    setDatos2({
      labels: ['Resueltos', 'Proceso', 'Enviados'],
      datasets: [
        {
          label: 'Reportes',
          data: [parseInt(reportesResueltos), parseInt(reportesProceso), parseInt(reportesEnviados)],
          options: {
            plugins: {
              legend: {
                display:false
              }
              
            },
            responsive:true,
            maintainAspectRatio:false
          },
          backgroundColor: [
            '#2D794E',
            '#82DDAA',
            '#FEFEFE',
          ],
          borderColor: [
            '#2D794E',
            '#82DDAA',
            '#82DDAA',
          ],
          borderWidth: 1,
          cutout: '85%'
        },
      ],
    })
    //chart.update();
  }, [datosDashboard, conteoMostrar]);   

  const manejaTipoConteo = (tipoConteo) => {
    setConteoMostrar(tipoConteo);
    if(tipoConteo==='mes') {
      setNumeroReportes(datosDashboard.datosDashboard[0].TotalReportesMesBaches);
      setConteoMostrar('mes');
    }
    if(tipoConteo==='historico') {
      setNumeroReportes(datosDashboard.datosDashboard[0].TotalReportesBaches);
      setConteoMostrar('historico');
    }
  }

  return (
    <>
  <div
    style={{
      display:'flex',
      flex:33,
      flexDirection:'column',
      backgroundColor:'white',
      borderRadius:10,
      padding:10,
      
    }}
  >
    <div
      style={{
        display:'flex',
        flexDirection:'row',
        flex:1,
        justifyContent:'space-between',
        alignItems:'center'
      }}
    >
      <div
        style={{
          display:'flex',
          flexDirection:'column',
        }}
      >
        <div>
          <Typography
            style={{
              fontSize:24,
              fontWeight:'bold'
            }}
          >Baches</Typography>
        </div>
        <div>
          <Typography
            style={{
              fontSize:12,
              fontWeight:'lighter',
              color:'#656565'
            }}
          
          >Datos históricos</Typography>
        </div>

      </div>
      <div>
        <Button
            onClick={(ev) => {manejaTipoConteo('mes')}}
            variant='outlined'
            //onClick={(event) => {setEstadoSeleccionado('todo')}}
            style={{
              color: conteoMostrar==='mes' ? 'white':'black',
              backgroundColor: conteoMostrar==='mes' ? '#642644':'white',
              borderRadius:10,
              borderColor:'transparent',
              textTransform:'none',
              fontSize:'.7rem',
              padding:3,
              minWidth:0

            }}
          >
            <span style={{fontSize:14, padding:5}}>Mes</span>
          </Button>
        <Button
            onClick={(ev) => {manejaTipoConteo('historico')}}

            variant='outlined'
            //onClick={(event) => {setEstadoSeleccionado('todo')}}
            style={{
              color: conteoMostrar==='historico' ? 'white':'black',
              backgroundColor: conteoMostrar==='historico' ? '#642644':'white',
              borderRadius:10,
              borderColor:'lightgray',
              textTransform:'none',
              fontSize:'.7rem',
              padding:3,
              marginLeft:10,
            }}
          >
            <span style={{fontSize:14, padding:5}}>Historico</span>
          </Button>

      </div>

    </div>
    <div
      style={{
        display:'flex',
        flexDirection:'row',
        flex:1, 
        backgroundColor:'transparent',
        justifyContent:'center'
      }}
    >
      <div
        style={{
          display:'flex',
          flexDirection:'row',
          justifyContent:'center',
       //   flex:30

        }}
      >
        <div
          style={{
            position:'relative'
          }}
        >
          <div
          style={{width: '100%', height: 40, position: 'absolute', top: '40%', left: 0, marginTop: -20, textAlign: 'center'}}
          >
          <Typography style={{fontSize:48, color:'#656565'}}>{efectividad}%</Typography>
          <Typography style={{fontSize:21, color:'#656565'}}>Efectividad</Typography>
          </div>
          { datos2?.datasets && (
          <Doughnut ref={chartReference}  data={datos2} />
          )}
        </div>
      </div>

    </div>
    <div style={{
      display:'flex',
      flexDirection:'row',
      justifyContent:'space-between',
      marginTop:20
    }}>

      <div
        style={{
          display:'flex',
          flexDirection:'column',
        }}
      >
        <Typography
          style={{
            fontSize:16,
            color:'#656565'
          }}
        >Reportes</Typography>
        <Typography
          style={{
            fontSize:28,
            color:'black',
            fontWeight:'bold',
          }}
        >{numeroReportes}</Typography>
      </div>
      <div
        style={{
          display:'flex',
          flexDirection:'column',

        }}
      >
        <div
          style={{
            display:'flex',
            flexDirection:'row',
            alignItems:'center',
            
          }}
        >
          <Punto style={{fontSize:12, color:'#2D794E'}} />
          <Typography
            style={{
              color:'#656565',
              fontSize:12,
              marginLeft:5
            }}
          >
            Resueltos
          </Typography>

        </div>
        <div
          style={{
            display:'flex',
            flexDirection:'row',
            alignItems:'center',
            
          }}
        >
          <Punto style={{fontSize:12, color:'#82DDAA'}} />
          <Typography
            style={{
              color:'#656565',
              fontSize:12,
              marginLeft:5
            }}
          >
            Proceso
          </Typography>

        </div>
        <div
          style={{
            display:'flex',
            flexDirection:'row',
            alignItems:'center',
            
          }}
        >
          <Punto1 style={{fontSize:12, color:'#82DDAA'}} />
          <Typography
            style={{
              color:'#656565',
              fontSize:12,
              marginLeft:5
            }}
          >
            Enviados
          </Typography>

        </div>
    </div>
    <div
      style={{
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'flex-start'
      }}
    >
      <div
        style={{
          display:'flex',
          flexDirection:'row',
          color:'#2D794E',
          alignItems:'flex-start'
        }}
      >
        <FlechaArriba /> 
        <Typography>
          62%
        </Typography>
      </div>
      <div>
        <Typography
          style={{
            fontSize:12,
            color:'#656565'
          }}
        >
          Último Mes
        </Typography>
      </div>
    </div>
    </div>

  </div>
  </>
  )
}
