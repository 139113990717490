import React, { useContext, useEffect, useState } from 'react'
import FlechaArriba from '@mui/icons-material/ArrowUpward';
import Laptop from '@mui/icons-material/LaptopWindows';
import Cell from '@mui/icons-material/PhoneIphone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { styled } from '@mui/material/styles';

import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { Button, Typography } from '@mui/material';
import { AuthContext } from '../context/AuthContext';


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  //borderRadius: 10,
  borderStartEndRadius:20,
  borderTopRightRadius:20,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 10,
    backgroundColor: theme.palette.mode === 'light' ? '#82DDAA' : '#308fe8',
  },
}));

export const TablaResumenReportes = () => {
  const {usuario} = useContext(AuthContext);
  const [reportesPlataforma, setReportesPlataforma] = useState([]);
  const [totalhistorico, settotalhistorico] = useState(0);
  const [totalmes, settotalmes] = useState(0);
  const [conteoMostrar, setConteoMostrar] = useState('mes')
const [numeroReportes, setNumeroReportes] = useState(0)
  useEffect(() => {
    obtenerDatosPlataforma();
  }, [])
  

  const obtenerDatosPlataforma = () => {
    fetch(process.env.REACT_APP_API_URL+'/api.reportesp.php?funcion=totalesPlataforma&idUsuario='+usuario.Usuarios_IdUsuario)
    .then(response => response.json())
    .then(datos => {
      setReportesPlataforma(datos);
      let total = 0;
      let totalmes1 = 0;
      datos.map(plataforma => {
        console.log(plataforma);
        total+=parseInt(plataforma.totalhistorico);
        totalmes1+=parseInt(plataforma.totalmes);
      })
      console.log('totales', total, totalmes1);
      settotalhistorico(total);
      settotalmes(totalmes1);

    })
    .catch(error => {
      console.log('Ocurrio un error', error)
    })
  }



  const valorPlataforma = (idPlataforma) => {
    const datosPlataforma = reportesPlataforma.filter(plataforma => plataforma.IdPlataforma==idPlataforma);
    console.log('plataforma encontrada',idPlataforma, datosPlataforma);
    if(conteoMostrar=='mes') {
      return (datosPlataforma[0]?.totalmes); 

    } else {
      return (datosPlataforma[0]?.totalhistorico); 

    }
  }

  const valorPorcentajePlataforma = (idPlataforma) => {
    const datosPlataforma = reportesPlataforma.filter(plataforma => plataforma.IdPlataforma==idPlataforma);
    console.log('plataforma encontrada',idPlataforma, datosPlataforma);
    if(conteoMostrar=='mes') {
      if(parseInt(datosPlataforma[0]?.totalmes)==0) {
        return 0;
      } else {
        console.log('porcentaje', parseInt(datosPlataforma[0]?.totalmes),totalmes, (parseInt(datosPlataforma[0]?.totalmes)/totalmes)*100) ;
        return ((parseInt(datosPlataforma[0]?.totalmes)/totalmes*100)); 

      }

    } else {
      if(parseInt(datosPlataforma[0]?.totalmes)==0) {
        return 0;
      } else {
        console.log('porcentaje', parseInt(datosPlataforma[0]?.totalhistorico),totalmes, (parseInt(datosPlataforma[0]?.totalhistorico)/totalmes)*100) ;
        return ((parseInt(datosPlataforma[0]?.totalhistorico)/totalhistorico*100)); 

      }

    }
  }

  


  const manejaTipoConteo = (tipoConteo) => {
    setConteoMostrar(tipoConteo);
    if(tipoConteo==='mes') {
//      setNumeroReportes(datosDashboard.datosDashboard[0].TotalReportesMesBaches);
      setConteoMostrar('mes');
    }
    if(tipoConteo==='historico') {
//      setNumeroReportes(datosDashboard.datosDashboard[0].TotalReportesBaches);
      setConteoMostrar('historico');
    }
  }


  return (
    <div
      style={{
        display:'flex',
        flexDirection:'column',
        marginBottom:20,
        backgroundColor:'transparent',
        padding:10,
        borderRadius:10,
        justifyContent:'space-between',
        height:'100%',
        //paddingLeft:35
        alignItems:'center'
        //minHeight:'45vh'
      }}
    >
      <div
        style={{
          display:'flex',
          flexDirection:'row',
          justifyContent:'space-between',
          width:'100%'
        }}
      >
        <div
          style={{
            display:'flex',
            flexDirection:'column',
            justifyContent:'flex-start',
            marginLeft:20
          }}
        >
          <Typography style={{fontSize:24, fontWeight:'bold'}}>Reportes</Typography>
          <Typography style={{fontSize:12, color:'#656565'}}>Por plataforma</Typography>
        </div>
        <div>
        <Button
            onClick={(ev) => {manejaTipoConteo('mes')}}
            variant='outlined'
            //onClick={(event) => {setEstadoSeleccionado('todo')}}
            style={{
              color: conteoMostrar==='mes' ? 'white':'black',
              backgroundColor: conteoMostrar==='mes' ? '#642644':'white',
              borderRadius:10,
              borderColor:'transparent',
              textTransform:'none',
              fontSize:'.7rem',
              padding:3,
              minWidth:0

            }}
          >
            <span style={{fontSize:14, padding:5}}>Mes</span>
          </Button>
        <Button
            onClick={(ev) => {manejaTipoConteo('historico')}}

            variant='outlined'
            //onClick={(event) => {setEstadoSeleccionado('todo')}}
            style={{
              color: conteoMostrar==='historico' ? 'white':'black',
              backgroundColor: conteoMostrar==='historico' ? '#642644':'white',
              borderRadius:10,
              borderColor:'lightgray',
              textTransform:'none',
              fontSize:'.7rem',
              padding:3,
              marginLeft:10,
            }}
          >
            <span style={{fontSize:14, padding:5}}>Historico</span>
          </Button>

      </div>

      </div>
      {/* <div
        style={{
          display:'flex',
          flexDirection:'row',
       //   flex:1, 
          //width:200,
          backgroundColor:'transparent',
          alignItems:'center',
          marginTop:20,
        }}
      >
        <Laptop style={{fontSize:20}} />
        <div
          style={{
            marginLeft:10,
            color:'#656565',
            fontSize:14,
            minWidth:110
          }}
        >
          Página Web
        </div>
        <div
          style={{
            minWidth:360,
            marginLeft:20
          }}
        >
          <BorderLinearProgress variant="determinate" value={valorPorcentajePlataforma(2)} />
          
        </div>
        <div
          style={{
            marginLeft:10,
            color:'#656565',
            fontSize:14
          }}
        >
          { valorPlataforma(2) }
        </div>
      </div> */}
      <div
        style={{
          display:'flex',
          flexDirection:'row',
       //   flex:1, 
          //width:200,
          backgroundColor:'transparent',
          alignItems:'center',
          marginTop:10
        }}
      >
        <Cell style={{fontSize:20}} />
        <div
          style={{
            marginLeft:10,
            color:'#656565',
            fontSize:14, minWidth:110
          }}
        >
          Aplicación Móvil
        </div>
        <div
          style={{
            minWidth:360,
            marginLeft:20
          }}
        >
          <BorderLinearProgress variant="determinate" value={valorPorcentajePlataforma(3)} />
          
        </div>
        <div
          style={{
            marginLeft:10,
            color:'#656565',
            fontSize:14
          }}
        >
          { valorPlataforma(3) }
        </div>
      </div>
      <div
        style={{
          display:'flex',
          flexDirection:'row',
       //   flex:1, 
          //width:200,
          backgroundColor:'transparent',
          alignItems:'center',
          marginTop:10
        }}
      >
        <WhatsAppIcon style={{fontSize:20}} />
        <div
          style={{
            marginLeft:10,
            color:'#656565',
            fontSize:14,
            minWidth:110
          }}
        >
          Whatsapp
        </div>
        <div
          style={{
            minWidth:360,
            marginLeft:20
          }}
        >
          <BorderLinearProgress variant="determinate" value={valorPorcentajePlataforma(1)} />
          
        </div>
        <div
          style={{
            marginLeft:10,
            color:'#656565',
            fontSize:14
          }}
        >
          { valorPlataforma(1) }
        </div>
      </div>
      {/* <div
        style={{
          display:'flex',
          flexDirection:'row',
       //   flex:1, 
          //width:200,
          backgroundColor:'transparent',
          alignItems:'center',
          marginTop:10
        }}
      >
        <SupportAgentIcon style={{fontSize:20}} />
        <div
          style={{
            marginLeft:10,
            color:'#656565',
            fontSize:14,
            minWidth:110
          }}
        >
          Linea telefónica
        </div>
        <div
          style={{
            minWidth:360,
            marginLeft:20
          }}
        >
          <BorderLinearProgress variant="determinate" value={valorPorcentajePlataforma(4)} />
          
        </div>
        <div
          style={{
            marginLeft:10,
            color:'#656565',
            fontSize:14
          }}
        >
          { valorPlataforma(4) }
        </div>
      </div> */}
      {/* <div
        style={{
          display:'flex',
          flexDirection:'row',
       //   flex:1, 
          //width:200,
          backgroundColor:'transparent',
          alignItems:'center',
          marginTop:10
        }}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="16.67" height="15" viewBox="0 0 28.56 28.204">
          <g id="Grupo_2397" data-name="Grupo 2397" transform="translate(-369.541 -669.947)">
            <path id="Trazado_3866" data-name="Trazado 3866" d="M5.915,22.033c-.55-.031-1.064-.023-1.567-.094A5.037,5.037,0,0,1,.027,17.057q-.052-6.051,0-12.1A5.037,5.037,0,0,1,4.977.019c4.49-.033,8.98-.012,13.47-.01.486,0,.741.284.742.81,0,1.367.01,2.734,0,4.1a.432.432,0,0,0,.261.44c.638.354,1.269.722,1.9,1.095a.345.345,0,0,0,.41.006q.976-.585,1.971-1.138a.354.354,0,0,0,.2-.361c-.006-1.376,0-2.753-.006-4.129a.748.748,0,0,1,.34-.73.742.742,0,0,1,.825.085,6.809,6.809,0,0,1,.368,11.393.447.447,0,0,0-.208.422q.007,4.656,0,9.312a3.568,3.568,0,0,1-7.011,1.008c-.07-.269-.178-.333-.438-.328-.863.017-1.728.014-2.591,0a.721.721,0,0,0-.573.232q-2.515,2.519-5.046,5.023a2.143,2.143,0,0,1-3.677-1.558c0-1.111,0-2.221,0-3.332v-.329M16.544,1.375h-.227c-3.731,0-7.462-.006-11.194,0a3.731,3.731,0,0,0-3.74,3.757q-.012,5.853,0,11.706a3.728,3.728,0,0,0,3.784,3.795c.456,0,.912-.007,1.367,0a.7.7,0,0,1,.746.725c.005.085,0,.171,0,.256,0,1.358,0,2.715,0,4.073a.777.777,0,0,0,.71.846.883.883,0,0,0,.719-.338q2.634-2.623,5.269-5.244a1.029,1.029,0,0,1,.785-.324c1.006.009,2.013,0,3.019,0,.1,0,.2-.01.3-.015.008-.067.016-.1.016-.141,0-2.8,0-5.6-.008-8.4a.472.472,0,0,0-.21-.32,6.8,6.8,0,0,1-1.895-9.664c.163-.23.344-.447.552-.717m1.28.759-.092-.06a2.651,2.651,0,0,1-.178.241A5.433,5.433,0,0,0,18.9,10.757a.988.988,0,0,1,.576.975c-.013,2.7-.007,5.391-.006,8.086,0,.579-.017,1.159.012,1.736a2.2,2.2,0,0,0,4.4-.249c0-3.208.007-6.416-.014-9.624a1.121,1.121,0,0,1,.6-1.084,5.433,5.433,0,0,0,1.069-8.3c-.061-.065-.131-.121-.241-.222V2.44c0,1.016,0,2.031,0,3.047a.809.809,0,0,1-.442.767Q23.42,7.07,22,7.9a.8.8,0,0,1-.882,0c-.94-.553-1.881-1.1-2.831-1.638a.825.825,0,0,1-.458-.809c.012-1.006,0-2.012,0-3.018q0-.151,0-.3" transform="translate(369.641 670.042)" stroke="#000" stroke-width="0.2"/>
            <path id="Trazado_3867" data-name="Trazado 3867" d="M58.907,179.534c1.594,0,3.188-.006,4.781.008a1.142,1.142,0,0,1,.577.173.577.577,0,0,1,.214.637.62.62,0,0,1-.454.5,1.571,1.571,0,0,1-.421.047q-4.724,0-9.449,0c-.057,0-.114,0-.171,0a.694.694,0,0,1-.72-.716.674.674,0,0,1,.748-.648q2.447,0,4.9,0" transform="translate(320.431 504.174)" stroke="#000" stroke-width="0.2"/>
            <path id="Trazado_3868" data-name="Trazado 3868" d="M57.217,136.977q-1.65,0-3.3,0a.669.669,0,0,1-.647-.381.637.637,0,0,1,.044-.675.687.687,0,0,1,.607-.31q3.3,0,6.6,0a.683.683,0,1,1-.032,1.362c-1.091,0-2.181,0-3.272,0" transform="translate(320.496 544.753)" stroke="#000" stroke-width="0.2"/>
            <path id="Trazado_3869" data-name="Trazado 3869" d="M56.155,91.961c.7,0,1.4,0,2.1,0a.672.672,0,0,1,.762.66.686.686,0,0,1-.757.7q-2.119.01-4.238,0a.693.693,0,0,1-.764-.7c.006-.41.3-.663.789-.664.7,0,1.4,0,2.1,0" transform="translate(320.433 585.081)" stroke="#000" stroke-width="0.2"/>
          </g>
        </svg>
        <div
          style={{
            marginLeft:10,
            color:'#656565',
            fontSize:14,
            minWidth:110
          }}
        >
          Internos
        </div>
        <div
          style={{
            minWidth:360,
            marginLeft:23
          }}
        >
          <BorderLinearProgress variant="determinate" value={valorPorcentajePlataforma(5)} />
          
        </div>
        <div
          style={{
            marginLeft:10,
            color:'#656565',
            fontSize:14,
          }}
        >
          { valorPlataforma(5) }
        </div>
      </div> */}
    </div>
  )
}
