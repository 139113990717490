import React, { createContext, useEffect, useState } from 'react'
import { ModalTamiteComponent } from '../components/ModalTramiteComponent';
import { RespuestasBot } from '../components/RespuestasBot';
import { TablaServiciosComponent } from '../components/TablaServiciosComponent'
import { TablaTramitesComponent } from '../components/TablaTramitesComponent';
import { ObtenerDependencias } from '../helpers/DependenciasHelper';
import { ObtenerTramites } from '../helpers/TramitesHelper';


export const TramitesContext = createContext();

export const TramitesPage = () => {
    const [tramiteSeleccionado, setTramiteSeleccionado] = useState();
    const [tramites, setTramites] = useState();
    const [modalTramites, setModalTramites] = useState();
    const [dependencias, setDependencias] = useState();
    const [opcionesDependencias, setOpcionesDependencias] = useState();
    const [editandoTramite, setEditandoTramite] = useState(false);

    const obtenerTramites = () => {
        ObtenerTramites().then(datos=> {
          setTramites(datos);
          console.log('tramites  obtenidos', datos);
        });
      }

    const obtenerDependencias = () => {
        ObtenerDependencias().then(datos => {
            const opciones = new Array();
            datos?.map(dato => {
              opciones.push({...opcionesDependencias,label:dato.Dependencia, id:dato.IdDependencia})
            })
            setOpcionesDependencias(opciones)
        })
    }

      useEffect(() => {
        obtenerTramites();
        obtenerDependencias();
      }, [])

      useEffect(() => {
        console.log('opciones dependencias', opcionesDependencias)
      }, [opcionesDependencias])
      
      
    


  return (
    <TramitesContext.Provider value={{editandoTramite, setEditandoTramite, tramiteSeleccionado, setTramiteSeleccionado, obtenerTramites, tramites, setModalTramites, modalTramites, dependencias, opcionesDependencias}}>
    <div
        style={{
            display:'flex',
            flexDirection:'row',
            flex:1
        }}
    >
        <div
            style={{
                display:'flex',
                flexDirction:'column',
                flex:50,
                backgroundColor:'transparent'
            }}  
        >
           <TablaTramitesComponent />
        </div>

    </div>
    <ModalTamiteComponent />
    </TramitesContext.Provider>
  )
}
