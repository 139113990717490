import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { SketchPicker } from 'react-color';
import { ObtenerDependencias } from '../helpers/DependenciasHelper';
import { GuardarTramite } from '../helpers/TramitesHelper';
import { GuardarUsuario } from '../helpers/UsuariosHelper';
import { UsuariosContext } from '../pages/UsuariosPage';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
export const ModalAgregaUsuario = () => {
  const {modalUsuario, setModalUsuario, obtenerCiudadanos, usuarioSeleccionado} = useContext(UsuariosContext);
  
  const [errorGuardado, setErrorGuardado] = useState(false);
  const [datosUsuario, setDatosUsuario] = useState();
  const [color, setColor] = useState();
  const [descripcion, setDescripcion] = useState();
  const [idSector, setIdSector] = useState(0);
  const [tiposUsuario, setTiposUsuario] = useState([
    {id:0,label:'Ciudadano'},
    {id:1,label:'Usuario'},
    {id:2, label:'Administrador'},
    {id:3, label:'Técnico'}
  ])
  const [dependencias, setDependencias] = useState();
  const [valorIdDependencia, setValorIdDependencia] = useState({id:0, label:'Dependencia a la que pertenece'});
  const [valorIdTipoUsuario, setValorIdTipoUsuario] = useState();


    const handleClose = () => {
        setModalUsuario(false);
    }

    useEffect(() => {
      console.log('cambio modal tramites', modalUsuario)
      obtenerDependencias();
    }, [modalUsuario])


    useEffect(() => {
      console.log('datos usuario', datosUsuario)
    }, [datosUsuario])
    
    useEffect(() => {
      console.log('el color cambio', color);
    }, [color])

    useEffect(() => {
      if(usuarioSeleccionado?.Usuarios_IdUsuario) {
        console.log('usuario seleccionado en effect de modal', usuarioSeleccionado)
        if(usuarioSeleccionado?.Usuarios_IdUsuario) {
          setDatosUsuario({
            userName: usuarioSeleccionado.Usuarios_UserName,
            nombre: usuarioSeleccionado.Usuarios_Nombre,
            aPaterno: usuarioSeleccionado.Usuarios_ApellidoPaterno,
            aMaterno: usuarioSeleccionado.Usuarios_ApellidoMaterno,
            password: usuarioSeleccionado.Usuarios_Password,
            idUsuario: usuarioSeleccionado.Usuarios_IdUsuario,
            idTipoUsuario: {id:usuarioSeleccionado.IdTipoUsuario, label:usuarioSeleccionado.tipoUsuario},
            idDependencia: {id:usuarioSeleccionado.IdDependencia, label:usuarioSeleccionado.Dependencia}
          })
          setValorIdTipoUsuario({
            id:usuarioSeleccionado.idTipoUsuario,
            label:usuarioSeleccionado.tipoUsuario
          })
          if(parseInt(usuarioSeleccionado?.idDependencia)>0) {
            console.log('entro a poner valores de dependencia')
            setValorIdDependencia({
              id:usuarioSeleccionado.idDependencia,
              label:usuarioSeleccionado.Dependencia
            })
          } else {
            setValorIdDependencia({
              id:0,
              label:'Seleccione una dependencia'
            })
          }
        }
      }
    }, [usuarioSeleccionado])
    


    
 
   const obtenerDependencias = () => {
    ObtenerDependencias().then(response => {
      let opciones = new Array()
      response?.map(dependencia => {
        opciones.push({id:dependencia.IdDependencia, label:dependencia.Dependencia})
      })
      setDependencias(opciones);
    })
   } 

    const handleSubmit =  (event) => {
      console.log('datos de usuario a guardar ', datosUsuario);
        event.preventDefault();
        //refForm.current.isValidate();
        GuardarUsuario(datosUsuario).then(respuesta => {
            console.log('respuesta de guardado en modal guardar usuario', respuesta);
            if(respuesta!=1) {
                setErrorGuardado(true);
            } else{
                setErrorGuardado(false);
                setDatosUsuario({});
                obtenerCiudadanos();
                handleClose();

            }
        })
    }

    const actualizaDatos = (dato, valor) => {
      setDatosUsuario({
          ...datosUsuario,
          [dato]:valor
      })
    }


  return (
      <div
        style={{
            minWidth:600
        }}
    >
     <Dialog open={modalUsuario} onClose={handleClose}>
        <form onSubmit={handleSubmit} >
        <DialogTitle>Usuario</DialogTitle>
        <DialogContent
            style={{minWidth:600}}
        >
          <TextField
            margin="dense"
            id="name"
            InputLabelProps={{ shrink: true }}
            label="Username"
            type="text"
            fullWidth
            variant="standard"
            required={true}
            onChange={(e) => actualizaDatos('userName',e.target.value)}
            value={datosUsuario?.userName}
          />
          <TextField
            margin="dense"
            id="name"
            InputLabelProps={{ shrink: true }}
            label="Nombre"
            type="text"
            fullWidth
            variant="standard"
            required={true}
            onChange={(e) => actualizaDatos('nombre',e.target.value)}
            value={datosUsuario?.nombre}
          />
          <TextField
            margin="dense"
            id="name"
            InputLabelProps={{ shrink: true }}
            label="Apellido Paterno"
            type="text"
            fullWidth
            variant="standard"
            required={true}
            onChange={(e) => actualizaDatos('aPaterno',e.target.value)}
            value={datosUsuario?.aPaterno}
          />
          <TextField
            margin="dense"
            id="name"
            InputLabelProps={{ shrink: true }}
            label="Apellido Materno"
            type="text"
            fullWidth
            variant="standard"
            required={true}
            onChange={(e) => actualizaDatos('aMaterno',e.target.value)}
            value={datosUsuario?.aMaterno}
          />
          <TextField
            margin="dense"
            id="name"
            InputLabelProps={{ shrink: true }}
            label="Password"
            type="password"
            fullWidth
            variant="standard"
            required={true}
            onChange={(e) => actualizaDatos('password',e.target.value)}
            value={datosUsuario?.password}
          />
          <Autocomplete
            variant="standard"
            onChange={(event, newValue) => {console.log('new value', newValue); setValorIdTipoUsuario({id:newValue.id, label:newValue.label}); actualizaDatos('idTipoUsuario', newValue)}}
            value={valorIdTipoUsuario}
            //defaultValue={tiposActivo[index]}
            //disablePortal
            id="combo-box-demo"
            options={tiposUsuario}
            sx={{ width: '100%', zIndex:999999999 }}
            style={{zIndex:99999999}}
            renderInput={(params) => <TextField variant="standard"  autoFocus style={{zIndex:9999999, backgroundColor:'white', marginTop:5}} {...params} label="Tipo de Usuario" />}
          />
          <Autocomplete
            variant="standard"
            onChange={(event, newValue) => {console.log('new value', newValue); setValorIdDependencia({id:newValue.id, label:newValue.label}); actualizaDatos('idDependencia', newValue)}}
            value={valorIdDependencia}
            //defaultValue={tiposActivo[index]}
            //disablePortal
            id="combo-box-demo"
            options={dependencias}
            sx={{ width: '100%', zIndex:999999999 }}
            style={{zIndex:99999999}}
            renderInput={(params) => <TextField variant="standard"  autoFocus style={{zIndex:9999999, backgroundColor:'white', marginTop:5}} {...params} label="Dependencia" />}
          />
          {
            errorGuardado && (
              <Typography
                style={{
                    fontSize:12,
                    color:'red'
                }}
              >
                Error al guardar
            </Typography>
            )
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button type='submit' >Guardar</Button>
        </DialogActions>
        </form>
      </Dialog>
    </div>
  )
}
