import { Button, TextField } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { GuardarTexto, ObtenerTextosBot } from '../helpers/ServiciosHelper';
import { ServiciosContext } from '../pages/ServiciosPage'

export const TextoBot = ({textoSeleccionado, setTextoSeleccionado, textosBot}) => {
    const {servicioSeleccionado} = useContext(ServiciosContext);

    useEffect(() => {
        console.log('cambio el texto seleccionado', textoSeleccionado)
    }, [textoSeleccionado])

    const guardarTexto = () => {
        GuardarTexto(textoSeleccionado);
    }

    useEffect(() => {
        console.log('cambiaron los textos bot');
        setTextoSeleccionado({})
    }, [textosBot]);

    useEffect(() => {
        console.log('cambio el servicio seleccionado en texto bot');
        setTextoSeleccionado({});
    }, [servicioSeleccionado])
    
    

  return (
    <div
        style={{minWidth:'90%',display:'flex', flexDirection:'column'}}
    >
        {/* {JSON.stringify(textoSeleccionado)} */}
        {
            textoSeleccionado?.texto && (
                <>
        <TextField 
            onChange={(e) => {
                    console.log(e.target.value);
                    setTextoSeleccionado({
                        ...textoSeleccionado,
                        texto:e.target.value
                    })
                }
            }
            style={{
                backgroundColor:'white',
                borderRadius:10,
                fontSize:12,
                minWidth:'90%',
                minHeight:'60vh'
            }}
            type='text'
            multiline={true}
            rows={15}
            placeholder="Texto"
            value={textoSeleccionado?.texto}
            
        />
        <Button
            variant="contained"
            onClick={()=> {guardarTexto()}}
        >
            Guardar
        </Button>
        </>
        )}
        
    </div>
  )
}
