import React, { createContext, useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { Button, FormControlLabel, IconButton, Switch, TextField, Typography } from '@mui/material';
import { AddCircle, Edit } from '@mui/icons-material';
import { ModalAgregaUsuario } from '../components/ModalAgregaUsuario';

export const UsuariosContext = createContext();

export const UsuariosPage = () => {
    const [ciudadanos, setCiudadanos] = useState([]);
    const [ciudadanosCompleto, setCiudadanosCompleto] = useState();
    const [usuarioSeleccionado, setUsuarioSeleccionado] = useState();
    const [permisos, setPermisos] = useState();
    const [modalUsuario, setModalUsuario] = useState(false);

    const filtrarUsuario = (usuario) => {
        let filtrado = ciudadanosCompleto?.filter(ciudadano => (ciudadano.Usuarios_Nombre+' '+ciudadano.Usuarios_ApellidoPaterno+' '+ciudadano.Usuarios_ApellidoMaterno).toLowerCase().includes(usuario.toLowerCase()));
        setCiudadanos(filtrado);
    }

    useEffect(() => {
        obtenerCiudadanos();
    }, [])

    const editarUsuario = async(usuario) => {
        console.log('usuario a editar', usuario)
        await setUsuarioSeleccionado(usuario);
        await setModalUsuario(true);
    }
    

    const obtenerCiudadanos = () => {
        fetch(process.env.REACT_APP_API_URL+'/api.reportesp.php?funcion=usuarios')
        .then(response => response.json())
        .then(arrCiudadanos => {
            console.log(arrCiudadanos);
            setCiudadanos(arrCiudadanos);
            setCiudadanosCompleto(arrCiudadanos);
          //console.log(reportes.length)
        })
        .catch(error => {
          console.log('Ocurrio un error', error)
        })
    }

    const obtenerPermisos = () => {
        const data = new FormData();
        data.append('idUsuario', usuarioSeleccionado.Usuarios_IdUsuario)
        fetch(process.env.REACT_APP_API_URL+'/usuariosp.php?funcion=permisos', {
            method:'POST',
            body:data
        })
        .then(response => response.json())
        .then(permisos => {
            console.log(permisos)
            setPermisos(permisos)
          //console.log(reportes.length)
        })
        .catch(error => {
          console.log('Ocurrio un error', error)
        })

    }

    useEffect(() => {
        if(usuarioSeleccionado?.Usuarios_IdUsuario) {
            obtenerPermisos();
        }
    }, [usuarioSeleccionado])

    const handleChecked = (e, valor, posicion) => {
        console.log('cambio el valor', e.target.checked, valor, usuarioSeleccionado.Usuarios_IdUsuario);
        let permiso = 0;
        if(e.target.checked) {
            permiso=1;
        }
        const data = new FormData();
        data.append('idUsuario', usuarioSeleccionado.Usuarios_IdUsuario);
        data.append('permiso', permiso);
        data.append('posicion', posicion);
        fetch(process.env.REACT_APP_API_URL+'/usuariosp.php?funcion=guardarPermiso', {
            method:'POST',
            body:data
        })
        .then(response => response.json())
        .then(permisos => {
            obtenerPermisos();
        })
        .catch(error => {
          console.log('Ocurrio un error', error)
        })
    }
    

  return (
    <UsuariosContext.Provider value={{ modalUsuario, setModalUsuario, obtenerCiudadanos, usuarioSeleccionado, setUsuarioSeleccionado}} >
    <div
        style={{
            display:'flex',
            //flex:1,
            margin:10,
            marginRight:20,
            marginTop:20,
            backgroundColor:'white',
            borderRadius:10,
            flexDirection:'row'
        }}
    >
        <div
            style={{
                display:'flex',
                flexDirection:'column',
                flex:50
            }}
        >
            <div
                style={{
                    display:'flex',
                    flexDirection:'row',
                    padding:20,
                    justifyContent:'flex-end'
                }}
            >
                <TextField 
                    variant='outlined'
                    size='small'
                    label="Buscar"
                    onChange={(value) => {console.log('valor buscar', value.target.value); filtrarUsuario(value.target.value)}}
                    style={{
                        marginRight:20,
                        minWidth:250
                    }}
                />

                
                <Button
                    onClick={() => {setUsuarioSeleccionado(); setModalUsuario(true)}}
                    variant="contained"
                    style={{
                        backgroundColor:'#642644',
                        color:'white',
                        textTransform:'unset',
                        borderRadius:10
                    }}
                >
                    <AddCircle style={{marginRight:5}} />  Agregar
                </Button>
            </div>
        <TableContainer
            
            style={{
                display:'flex',
                flex:1,
                maxHeight:'70vh',
                maxWidth:'50vw'
                
            }}
        >
            <Table aria-aria-label='a dense table' stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>Nombre</TableCell>
                        <TableCell>A. Paterno</TableCell>
                        <TableCell>A. Materno</TableCell>
                        <TableCell>Tipo Usuario</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody
                    style={{
                        fontSize:'.7rem'
                    }}
                >
                {
                    ciudadanos.map(ciudadano => (
                        <TableRow
                            onClick={() => {console.log('click en renglon', ciudadano); setUsuarioSeleccionado(ciudadano)}}
                            style={{
                                maxHeight:20,
                                height:20,
                                backgroundColor: usuarioSeleccionado?.Usuarios_IdUsuario==ciudadano.Usuarios_IdUsuario ? '#642644':'white',
                                color: usuarioSeleccionado?.Usuarios_IdUsuario==ciudadano.Usuarios_IdUsuario ? 'white':'black',
                            }}
                        >
                            <TableCell style={{maxHeight:20, color: usuarioSeleccionado?.Usuarios_IdUsuario==ciudadano.Usuarios_IdUsuario ? 'white':'black',}}>
                               {ciudadano.Usuarios_Nombre}
                            </TableCell>
                            <TableCell style={{maxHeight:20, color: usuarioSeleccionado?.Usuarios_IdUsuario==ciudadano.Usuarios_IdUsuario ? 'white':'black',}}>
                                {ciudadano.Usuarios_ApellidoPaterno}
                            </TableCell>
                            <TableCell style={{maxHeight:20, color: usuarioSeleccionado?.Usuarios_IdUsuario==ciudadano.Usuarios_IdUsuario ? 'white':'black',}}>
                               {ciudadano.Usuarios_ApellidoMaterno}
                            </TableCell>
                            <TableCell style={{maxHeight:20, color: usuarioSeleccionado?.Usuarios_IdUsuario==ciudadano.Usuarios_IdUsuario ? 'white':'black',}}>
                                {ciudadano.tipoUsuario}
                            </TableCell>
                            <TableCell>
                                <IconButton
                                    size='small'
                                    onClick={()=>{setUsuarioSeleccionado(ciudadano); setModalUsuario(true)}}
                                >
                                    <Edit style={{color:'green'}} />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))
                }
                </TableBody>
            </Table>
        </TableContainer>
        </div>
        <div
            style={{
                display:'flex',
                flexDirection:'column',
                marginLeft:20,
                flex:40
            }}
        >
            {
                permisos?.map(grupo => (
                    <>
                    <div
                        style={{
                            display:'flex',
                            flexDirection:'row',
                            //flex:1,
                            marginLeft:20,
                            marginTop:20,
                            backgroundColor:'#642644',
                            color:'white',
                            padding:10,
                            borderTopLeftRadius:10,
                            borderTopRightRadius:10,
                            marginRight:10
                        }}
                    >
                        {grupo.grupo}
                    </div>
                    <>
                    {
                        grupo?.permisos?.map(permiso => (
                            
                            <div
                                style={{
                                    flexDirection:'row',
                                    //flex:1,
                                    marginLeft:20,

                                }}
                            >
                                <FormControlLabel label={permiso.nombrePermiso} control={<Switch checked={permiso?.valor=='1' ? true:false}  onChange={(e) => {handleChecked(e, permiso.valor, permiso.posicion)}}  />} />
                            </div>
                        ))
                    }
                    </>
                    </>
                ))
            }
        </div>
    </div>
    <ModalAgregaUsuario />
    </UsuariosContext.Provider>
  )
}
