import React, { useContext } from 'react'
import { ServiciosContext } from '../pages/ServiciosPage'

export const ServicioComponent = ({servicio}) => {
    const {servicioSeleccionado, setServicioSeleccionado} = useContext(ServiciosContext);
  return (
    <div
    onClick={() => setServicioSeleccionado(servicio)}
    style={{
      display:'flex',
      flexDirection:'row',
      fontWeight:'normal',
      fontSize:13,
      padding:10,
      boxShadow: (servicioSeleccionado?.IdTipoReporte==servicio.IdTipoReporte)  ? '1px 2px 9px #0f0f0f':'',
      borderRadius:10
    }}
  >
    <div
      style={{
        display:'flex',
        flex:5
      }}
    >
      {servicio.IdTipoReporte}
    </div>
    <div
      style={{
        display:'flex',
        flex:30
      }}
    >
      {servicio.TipoReporte}
    </div>
    <div
      style={{
        display:'flex',
        flex:10,
        color:servicio.Color
      }}
    >
      {servicio.Color}
    </div>
    <div
      style={{
        display:'flex',
        flex:10
      }}
    >
      {servicio.Icono}
    </div>
    <div
      style={{
        display:'flex',
        flex:20
      }}
    >
      {servicio.OrigenIcono}
    </div>
    <div
      style={{
        display:'flex',
        flex:10
      }}
    >
      {servicio.Orden}
    </div>

  </div>
  )
}
