import { Button, IconButton, InputAdornment, TextField } from '@mui/material'
import { fontWeight } from '@mui/system'
import React, { createContext, useContext, useEffect, useState } from 'react'
import Search from '@mui/icons-material/Search';
import Abajo from '@mui/icons-material/ArrowDownward';
import Arriba from '@mui/icons-material/ArrowUpward';
import Laptop from '@mui/icons-material/LaptopWindows';
import Cell from '@mui/icons-material/PhoneIphone';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/es-mx';

import { AdapterDayjs, esMX } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { TablaReportes } from '../components/TablaReportes';
import { esES } from '@mui/x-date-pickers';
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjs, { Dayjs } from "dayjs"
import { ModalReporte } from '../components/ModalReporte';
import { ModalEdicionReporte } from '../components/ModalEdicionReporte';
import { ModalAsignarTecnico } from '../components/ModalAsignarTecnico';
import { GuardarUsuario } from '../helpers/UsuariosHelper';
import { AuthContext } from '../context/AuthContext';
//import { useSocket } from '../hooks/useSocket';
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault("Mexico/Chihuahua")

//dayjs.tz.setDefault("America/New_York")
// or for Day.js
export const ReportesContext = createContext();

export const ReportesPage = () => {
  const {usuario} = useContext(AuthContext);
 // const {socket} = useSocket();
  const [estadoSeleccionado, setEstadoSeleccionado] = useState('todo');
  const [ordenSeleccionado, setOrdenSeleccionado] = useState('enviado');
  const [reportesTabla, setReportesTabla] = useState([]);
  const [todosReportes, setTodosReportes] = useState([]);
  const [value, setValue] = useState();
  const [fechaInicio, setFechaInicio] = useState();
  const [fechaFin, setFechaFin] = useState();
  const [inicio, setInicio] = useState();
  const [fin, setFin] = useState();
  const [modoOrden, setModoOrden] = useState('asc');
  const [modalReporte, setModalReporte] = useState(false)
  const [modalEdicionReporte, setModalEdicionReporte] = useState(false)
  const [reporteSeleccionado, setReporteSeleccionado] = useState()
  const [totalReportesVista, setTotalReportesVista] = useState(0)
  const [modalAsignarTecnico, setModalAsignarTecnico] = useState(false)

useEffect(() => {
  let estadoValor=0;
  switch(estadoSeleccionado) {
    case 'enviados':
      estadoValor=1
    break;
    case 'todo':
      estadoValor=0;
    break;
    case 'proceso':
      estadoValor=2;
    break;
    case 'resueltos':
      estadoValor=3;
    break;
  }
  if(estadoValor>0) {
      let filtrados = todosReportes.filter(reporte => parseInt(reporte.IdEstatus)===estadoValor);
      setReportesTabla(filtrados);
  } else {
    setReportesTabla(todosReportes);
  }
  console.log('cambio el estado seleccionado', estadoSeleccionado);
}, [estadoSeleccionado, todosReportes]);

useEffect(() => {
  console.log('cambiaron los reportes,aplicar filtros y orden', reportesTabla);

}, [reportesTabla])

// useEffect(() => {
//   console.log(socket);
// }, [socket])


useEffect(() => {

  let fecha2 = dayjs.tz(fechaInicio, "America/Chihuahua");
  console.log('fecha2', fecha2.subtract(6,'hours').toISOString());
  setInicio(fecha2.subtract(6,'hours').toISOString())

  console.log('Cambio fecha de inicio', fechaInicio?.toISOString());

}, [fechaInicio])
useEffect(() => {

  let fecha2 = dayjs.tz(fechaFin, "America/Chihuahua");
  console.log('fecha2', fecha2.subtract(6,'hours').toISOString());
  setFin(fecha2.subtract(6,'hours').toISOString())
  
  console.log('Cambio fecha de fin', fechaFin?.toISOString());

}, [fechaFin])

useEffect(() => {
  Reportes()

}, [inicio, fin]);



const handleChange = (newValue) => {
  setValue(newValue);
};

const Reportes = () => {
  
  fetch(process.env.REACT_APP_API_URL+'/api.reportesp.php?funcion=reportes&inicio='+inicio+'&fin='+fin)
  .then(response => response.json())
  .then(reportes => {
    setReportesTabla(reportes);
    setTodosReportes(reportes);
    console.log(reportes.length)
  })
  .catch(error => {
    console.log('Ocurrio un error', error)
  })
}

useEffect(() => {
  console.log(process.env.REACT_APP_API_URL);
  Reportes();
}, [])

function custom_sort(a, b) {
  return new Date(b.lastUpdated).getTime() - new Date(a.lastUpdated).getTime();
}

  useEffect(() => {
    console.log('ordenando por',ordenSeleccionado, modoOrden);
    if(ordenSeleccionado==='enviado' && modoOrden==='asc') {
      setReportesTabla(reportesTabla.sort((a, b) => (dayjs(a.Fecha).isAfter(dayjs(b.Fecha)) ? 1 : -1)));
    }
    if(ordenSeleccionado==='enviado' && modoOrden==='desc') {
      setReportesTabla(reportesTabla.sort((a, b) => (dayjs(a.Fecha).isBefore(dayjs(b.Fecha)) ? 1 : -1)));
    }
    if(ordenSeleccionado==='finalizado' && modoOrden==='asc') {
      console.log('ordenando por finalzado asc');
      setReportesTabla(reportesTabla.sort((a, b) => (dayjs(a.Resuelto).isAfter(dayjs(b.Resuelto)) ? 1 : -1)));
    }
    if(ordenSeleccionado==='finalizado' && modoOrden==='desc') {
      console.log('ordenando por finalzado desc');
      setReportesTabla(reportesTabla.sort((a, b) => (dayjs(a.Resuelto).isBefore(dayjs(b.Resuelto)) ? 1 : -1)));
    }
    if(ordenSeleccionado==='urgencia' && modoOrden==='asc') {
      console.log('ordenando por finalzado asc');
      setReportesTabla(reportesTabla.sort((a, b) => (parseInt(a.IdPrioridad) - parseInt(b.IdPrioridad))));
    }
    if(ordenSeleccionado==='urgencia' && modoOrden==='desc') {
      console.log('ordenando por finalzado desc');
      setReportesTabla(reportesTabla.sort((a, b) =>  (parseInt(b.IdPrioridad) - parseInt(a.IdPrioridad))));
    }

  }, [ordenSeleccionado, modoOrden]);

  const handleOrder = (orden) => {
    if(orden===ordenSeleccionado) {
      modoOrden==='asc' ? setModoOrden('desc'):setModoOrden('asc');
    } else {
      setOrdenSeleccionado(orden);
      setModoOrden('asc');
    }
  }

  useEffect(() => {
    console.log('modo orden',ordenSeleccionado, modoOrden);
  }, [modoOrden]);
  
useEffect(() => {
  console.log('cambio reportes tabla', reportesTabla);
}, [reportesTabla])

const exportarExcel=()=> {
  console.log("https://quejas.juarez.gob.mx/servicios/exportar.reportes.php?inicio="+inicio+"&fin="+fin);
  window.open("https://quejas.juarez.gob.mx/servicios/exportar.reportes.php?inicio="+inicio+"&fin="+fin+'&idUsuario='+usuario.Usuarios_IdUsuario)
}

  return (
    <ReportesContext.Provider value={{modalReporte, setModalReporte, reporteSeleccionado, setReporteSeleccionado, modalEdicionReporte, setModalEdicionReporte, totalReportesVista, setTotalReportesVista, modalAsignarTecnico, setModalAsignarTecnico, Reportes}} >
    <LocalizationProvider timezone="America/Mexico" dateAdapter={AdapterDayjs} adapterLocale="mx">

    <div
        style={{
          display:'flex',
          flexDirection:'column',
          flex:1,
            color:'black',
            marginTop:10,
            marginLeft:40,
            marginRight:20
        }}
    >
      <div
        style={{
          display:'flex',
          flexDirection:'row',
          flex:1,
          alignItems:'center',
          maxHeight:70,
          justifyContent:'space-between'
        }}
      >
        <div

        >
          <span
            style={{
              fontSize:40,
              fontWeight:'bold'
            }}
          >
            Reportes
          </span>
          <span
            style={{
              fontSize:28,
              fontWeight:'regular',
              marginLeft:30
            }}
          >
            {totalReportesVista}
          </span>
        </div>
        <div>
        <TextField
           // label="Buscar Reporte"
            variant='standard'
            placeholder='Buscar Reporte'
            style={{
              backgroundColor:'white',
              borderRadius:10,
              minWidth:300,
              borderBottomColor:'white',

            }}
            InputProps={{
              disableUnderline:true,
              endAdornment: (
                <InputAdornment>
                  <IconButton>
                    <Search />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </div>
      </div>
      <div
        style={{
          display:'flex',
          flexDirection:'row',
          flex:1,
          alignItems:'center',
          maxHeight:60,
          justifyContent:'space-between'
        }}
      >
        <Button
          variant='outlined'
          onClick={(event) => {setEstadoSeleccionado('todo')}}
          style={{
            color: estadoSeleccionado==='todo' ? 'white':'black',
            backgroundColor: estadoSeleccionado==='todo' ? '#642644':'white',
            borderRadius:10,
            borderColor:'transparent',
            textTransform:'none',
            fontSize:'.7rem',
            padding:3,
            minWidth:0

          }}
        >
          <span>Todo</span>
        </Button>
        {/* <Button
          onClick={(event) => {setEstadoSeleccionado('enviados')}}
          variant='outlined'
          style={{
            color: estadoSeleccionado==='enviados' ? 'white':'black',
            backgroundColor: estadoSeleccionado==='enviados' ? '#642644':'white',
            borderRadius:10,
            borderColor:'transparent',
            textTransform:'none',
            marginLeft:7,
            fontSize:'.7rem'


          }}
        >
          <span>Enviados</span>
        </Button> */}
        <Button
          onClick={(event) => {setEstadoSeleccionado('proceso')}}
          variant='outlined'
          style={{
            color: estadoSeleccionado==='proceso' ? 'white':'black',
            backgroundColor: estadoSeleccionado==='proceso' ? '#642644':'white',
            borderRadius:10,
            borderColor:'transparent',
            textTransform:'none',
            marginLeft:7,
            fontSize:'.7rem',
            padding:3,
            minWidth:0


          }}
        >
          <span>Proceso</span>
        </Button>
        <Button
          onClick={(event) => {setEstadoSeleccionado('resueltos')}}
          variant='outlined'
          style={{
            color: estadoSeleccionado==='resueltos' ? 'white':'black',
            backgroundColor: estadoSeleccionado==='resueltos' ? '#642644':'white',
            borderRadius:10,
            borderColor:'transparent',
            textTransform:'none',
            marginLeft:6,
            fontSize:'.7rem',
            padding:3,
            minWidth:0


          }}
        >
          <span>Resueltos</span>
        </Button>
        <span
          style={{
            fontSize:10,
            marginLeft:20
          }}
        >
          De
        </span>
        <DesktopDatePicker
          LocalizationProvider
          timezone="America/Chihuahua"
          type="datetime-local"
          style={{
            backgroundColor:'white',
            borderColor:'transparent',
            maxHeight:30, disableUnderline:true,
            width:100,
            marginLeft:5,
            fontSize:'.7rem'
          }}
          variant="standard"
          //label="De"
          placeholder='De'
          inputFormat="DD/MM/YYYY"
          value={fechaInicio}
          onChange={(newValue) => {setFechaInicio(newValue)}}
          renderInput={(params) =>  <TextField variant='standard' style={{backgroundColor:'white', borderColor:'transparent', disableUnderline:true, width:124, marginLeft:5, borderRadius:10, fontSize:'.7rem'}} {...params} />}
        />
        <span
          style={{
            fontSize:10,
            marginLeft:20
          }}
        >
          Hasta
        </span>
        <DesktopDatePicker
          style={{
            backgroundColor:'white',
            borderColor:'transparent',
            maxHeight:30, disableUnderline:true,
            width:100,
            marginLeft:5          }}
          variant="standard"
          //label="De"
          placeholder='De'
          inputFormat="DD/MM/YYYY"
          value={fechaFin}
          onChange={(newValue) => {setFechaFin(newValue)}}
          renderInput={(params) =>  <TextField variant='standard' style={{backgroundColor:'white', borderColor:'transparent', disableUnderline:true, width:124, marginLeft:5, borderRadius:10}} {...params} />}
        />
        <Button
          variant='outlined'
          onClick={(event) => { handleOrder('enviado')}}
          style={{
            color: ordenSeleccionado==='enviado' ? 'white':'black',
            backgroundColor: ordenSeleccionado==='enviado' ? '#642644':'white',
            borderRadius:10,
            borderColor:'transparent',
            textTransform:'none', 
            marginLeft:20,
            alignItems:'center',
            fontSize:'.7rem',
            padding:3,
            minWidth:0

          }}
        >
          <div
            style={{
              backgroundColor:'transparent',
              display:'flex',
              flexDirection:'row',
              alignItems:'center',
              justifyContent:'center'
            }}
          >
            <span
              style={{
              }}
            >Fecha Enviado </span><Arriba style={{fontSize:15, padding:0, marginRight:0}} /> <Abajo style={{fontSize:15, backgroundColor:'transparent', padding:0, marginLeft:0}} />
          </div>
        </Button>
        <Button
          variant='outlined'
          onClick={(event) => { handleOrder('finalizado')}}
          style={{
            color: ordenSeleccionado==='finalizado' ? 'white':'black',
            backgroundColor: ordenSeleccionado==='finalizado' ? '#642644':'white',
            borderRadius:10,
            borderColor:'transparent',
            textTransform:'none', 
            marginLeft:7,
            alignItems:'center',
            fontSize:'.7rem',
            padding:3,
            minWidth:0

          }}
        >
          <div
            style={{
              backgroundColor:'transparent',
              display:'flex',
              flexDirection:'row',
              alignItems:'center',
              justifyContent:'center'
            }}
          >
            <span
              style={{
              }}
            >Fecha Finalizado </span><Arriba style={{fontSize:15, padding:0, marginRight:0}} /> <Abajo style={{fontSize:15, backgroundColor:'transparent', padding:0, marginLeft:0}} />
          </div>
        </Button>
        <Button
          variant='outlined'
          onClick={(event) => {handleOrder('urgencia')}}
          style={{
            color: ordenSeleccionado==='urgencia' ? 'white':'black',
            backgroundColor: ordenSeleccionado==='urgencia' ? '#642644':'white',
            borderRadius:10,
            borderColor:'transparent',
            textTransform:'none', 
            marginLeft:7,
            alignItems:'center',
            fontSize:'.7rem',
            padding:3,
            minWidth:0

          }}
        >
          <div
            style={{
              backgroundColor:'transparent',
              display:'flex',
              flexDirection:'row',
              alignItems:'center',
              justifyContent:'center'
            }}
          >
            <span
              style={{
              }}
            >Urgencia </span><Arriba style={{fontSize:15, padding:0, marginRight:0}} /> <Abajo style={{fontSize:15, backgroundColor:'transparent', padding:0, marginLeft:0}} />
          </div>
        </Button>
        <Button
          variant='outlined'
          onClick={(event) => {setOrdenSeleccionado('colonias')}}
          style={{
            color: ordenSeleccionado==='colonias' ? 'white':'black',
            backgroundColor: ordenSeleccionado==='colonias' ? '#642644':'white',
            borderRadius:10,
            borderColor:'transparent',
            textTransform:'none', 
            marginLeft:7,
            alignItems:'center',
            fontSize:'.7rem',
            padding:3,
            minWidth:0

          }}
        >
          <div
            style={{
              backgroundColor:'transparent',
              display:'flex',
              flexDirection:'row',
              alignItems:'center',
              justifyContent:'center'
            }}
          >
            <span
              style={{
              }}
            >Colonias </span><Arriba style={{fontSize:15, padding:0, marginRight:0}} /> <Abajo style={{fontSize:15, backgroundColor:'transparent', padding:0, marginLeft:0}} />
          </div>
        </Button>
        {/* <Button
          variant='outlined'
          onClick={(event) => {setOrdenSeleccionado('colonias')}}
          style={{
            color: ordenSeleccionado==='colonias' ? 'white':'black',
            backgroundColor: ordenSeleccionado==='colonias' ? '#642644':'white',
            borderRadius:10,
            borderColor:'transparent',
            textTransform:'none', 
            marginLeft:7,
            alignItems:'center',
            padding:3,
            minWidth:0


          }}
        >
            <Laptop style={{fontSize:20, padding:0, marginRight:0, }} /> 
        </Button> */}
        <Button
          variant='outlined'
          onClick={(event) => {setOrdenSeleccionado('colonias')}}
          style={{
            color: ordenSeleccionado==='colonias' ? 'white':'black',
            backgroundColor: ordenSeleccionado==='colonias' ? '#642644':'white',
            borderRadius:10,
            borderColor:'transparent',
            textTransform:'none', 
            marginLeft:7,
            alignItems:'center',
            padding:3,
            minWidth:0

          }}
        >
            <Cell style={{fontSize:20, padding:0, marginRight:0, }} /> 
        </Button>
        <Button
          variant='outlined'
          onClick={(event) => {setOrdenSeleccionado('colonias')}}
          style={{
            color: ordenSeleccionado==='colonias' ? 'white':'black',
            backgroundColor: ordenSeleccionado==='colonias' ? '#642644':'white',
            borderRadius:10,
            borderColor:'transparent',
            textTransform:'none', 
            marginLeft:7,
            alignItems:'center',
            padding:3,
            minWidth:0

          }}
        >
            <WhatsAppIcon style={{fontSize:20, padding:0, marginRight:0, }} /> 
        </Button>
        {/* <Button
          variant='outlined'
          onClick={(event) => {setOrdenSeleccionado('colonias')}}
          style={{
            color: ordenSeleccionado==='colonias' ? 'white':'black',
            backgroundColor: ordenSeleccionado==='colonias' ? '#642644':'white',
            borderRadius:10,
            borderColor:'transparent',
            textTransform:'none', 
            marginLeft:7,
            alignItems:'center',
            padding:3,
            minWidth:0
          }}
        >



            <SupportAgentIcon style={{fontSize:20, padding:0, marginRight:0, }} /> 
        </Button> */}
        <Button
          variant='outlined'
          onClick={(event) => {exportarExcel()}}
          style={{
            color: 'white',
            backgroundColor: '#642644',
            borderRadius:10,
            borderColor:'transparent',
            textTransform:'none', 
            marginLeft:7,
            alignItems:'center',
            fontSize:'.7rem',
            padding:3,
            minWidth:0

          }}
        >
          <div
            style={{
              backgroundColor:'transparent',
              display:'flex',
              flexDirection:'row',
              alignItems:'center',
              justifyContent:'center'
            }}
          >
            <span
              style={{
              }}
            >Exportar Excel </span><Arriba style={{fontSize:15, padding:0, marginRight:0}} /> <Abajo style={{fontSize:15, backgroundColor:'transparent', padding:0, marginLeft:0}} />
          </div>
        </Button>

      </div>      
      <div
        style={{
          display:'flex',
          

        }}
      >
        {
          reportesTabla.length && (
        <TablaReportes reportesTabla={reportesTabla} />
          )
        }
      </div>
    </div>
    <ModalReporte />
    <ModalEdicionReporte />
    <ModalAsignarTecnico />
    </LocalizationProvider>
    </ReportesContext.Provider>
  )
}
