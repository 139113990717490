export const ObtenerTramites = async() => {
    return new Promise((resolve, reject) => {

        const data = new FormData();
       // data.append('orden','TipoInstalacion asc')
         fetch(process.env.REACT_APP_API_URL+'/api.tramites.php?funcion=tramites', {
            method:'POST',
            body:data
        })
        .then(response => response.json())
        .then(datos => resolve(datos))
    })
    .then((datos) => {
        return datos
    })
    .catch(error => {
        console.warn(error);
    })
}

export const GuardarTramite = async(tramite) => {
    return new Promise((resolve, reject) => {
        console.log(tramite)
        const data = new FormData();
        data.append('idDependencia',tramite.idDependencia);
         fetch(process.env.REACT_APP_API_URL+'/api.tramites.php?funcion=guardarTramite', {
            method:'POST',
            body:JSON.stringify(tramite)
        })
        .then(response => response.json())
        .then(datos => resolve(datos))
    })
    .then((datos) => {
        return datos
    })
    .catch(error => {
        console.warn(error);
    })
}

export const EliminarTramite = async(tramite) => {
    return new Promise((resolve, reject) => {
        console.log(tramite)
        const data = new FormData();
        data.append('idDependencia',tramite.idDependencia);
         fetch(process.env.REACT_APP_API_URL+'/api.tramites.php?funcion=eliminarTramite', {
            method:'POST',
            body:JSON.stringify(tramite)
        })
        .then(response => response.json())
        .then(datos => resolve(datos))
    })
    .then((datos) => {
        return datos
    })
    .catch(error => {
        console.warn(error);
    })
}