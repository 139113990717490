export const ObtenerServicios = async() => {
    return new Promise((resolve, reject) => {

        const data = new FormData();
        data.append('orden','TipoInstalacion asc')
         fetch(process.env.REACT_APP_API_URL+'/api.reportesp.php?funcion=tiposReporte', {
            method:'POST',
            body:data
        })
        .then(response => response.json())
        .then(datos => resolve(datos))
    })
    .then((datos) => {
        return datos
    })
    .catch(error => {
        console.warn(error);
    })
}

export const ObtenerTextosBot = async(idTipoReporte) => {
    return new Promise((resolve, reject) => {

         fetch(process.env.REACT_APP_API_URL+'/api.reportesp.php?funcion=respuestasBot&idTipoReporte='+idTipoReporte, {
            method:'POST',
        })
        .then(response => response.json())
        .then(datos => resolve(datos))
    })
    .then((datos) => {
        return datos
    })
    .catch(error => {
        console.warn(error);
    })
}

export const GuardarTexto = async(datosTexto) => {
    return new Promise((resolve, reject) => {
        let data = new FormData();
        data.append('id', datosTexto.id);
        data.append('texto', datosTexto.texto);
        data.append('tabla', datosTexto.tabla);
         fetch(process.env.REACT_APP_API_URL+'/api.reportesp.php?funcion=guardarTexto', {
            method:'POST',
            body:data
        })
        .then(response => response.json())
        .then(datos => resolve(datos))
    })
    .then((datos) => {
        return datos
    })
    .catch(error => {
        console.warn(error);
    })
}


