import React, { Children, createContext } from 'react'
import { useState } from 'react';

 export const AuthContext = createContext();

 export const AuthProvider = (props) => {
  const [usuarios, setUsuarios] = useState([]);
  const [usuario, setUsuario] = useState([]);
  const [logueado, setLogueado] = useState(false);
  const [URL, setURL] = useState('https://quejas.juarez.gob.mx/servicios');
  const [mostrarFiltros, setMostrarFiltros] = useState(false);
  const [ambito, setAmbito] = useState();
  const [anio, setAnio] = useState();
  const [opcionesCapas1, setOpcionesCapas1] = useState();
  const [secciones, setSecciones] = useState();
  const [mostrarOpcionesUsuario, setMostrarOpcionesUsuario] = useState(false);
  const [modalAgregarUsuario, setModalAgregarUsuario] = useState(false);
  const [modalAgregarSimpatizante, setModalAgregarSimpatizante] = useState(false);
  const [modalAgregarMilitante, setModalAgregarMilitante] = useState(false);
  const [mostrarOpcionesSimpatizantes, setMostrarOpcionesSimpatizantes] = useState(false);
  const [mostrarOpcionesMilitantes, setMostrarOpcionesMilitantes] = useState(false);
 const [mostrarOpcionesComite, setMostrarOpcionesComite] = useState(false)
 const [filtroIntegrantes, setFiltroIntegrantes] = useState('');
 const [mostrarDatosCapa, setMostrarDatosCapa] = useState();
 const [idMunicipioSeleccionado, setIdMunicipioSeleccionado] = useState();
 const [seccionSeleccionada, setSeccionSeleccionada] = useState(0);
 const [titulo, setTitulo] = useState('');
 const [datosPartido, setDatosPartido] = useState([]);
 const [estadoModalFotografiaMilitante, setEstadoModalFotografiaMilitante] = useState(false)
const [idMilitanteSeleccionado, setIdMilitanteSeleccionado] = useState(0);
const [idSimpatizanteSeleccionado, setIdSimpatizanteSeleccionado] = useState(0);
  const [integranteSeleccionado, setIntegranteSeleccionado] = useState();
const [idSimpatizanteEdicion, setIdSimpatizanteEdicion] = useState();
const [modalAgregaOpinion, setModalAgregaOpinion] = useState(false);
const [modalCambiaPassword, setModalCambiaPassword] = useState(false);
const [aplicarFiltro, setAplicarFiltro] = useState(0);
const [idDependenciaMostrar, setIdDependenciaMostrar] = useState();


  const obtenerUsuarios = () =>{
    fetch(URL + '/usuariosp.php?funcion=usuarios')
    .then(response => response.json())
    .then(data => {
        console.log(data);
        setUsuarios(data);
    })
    .catch(error =>{
        console.log('error',error);
    })
}

  const onLogin =(usuario,password) =>{
    console.log('URL de apis', process.env.REACT_APP_API_URL)
    fetch(process.env.REACT_APP_API_URL+'/usuariosp.php?funcion=login&usuario='+usuario+'&password='+password)
     .then(response =>response.json())
     .then(data=> {
       console.log('Datos obtenidos del api',data);
       if(data?.Usuarios_IdUsuario) {
        setLogueado(true);
        setUsuario(data)
        if(data.IdTipoUsuario!="2") {
          setIdDependenciaMostrar(data.idDependencia)
        } else {
          setIdDependenciaMostrar(0);
        }
       }
     })
  }
  return(
  <AuthContext.Provider value={{usuario,logueado,setUsuario,setLogueado, onLogin, URL, mostrarFiltros, setMostrarFiltros, ambito, setAmbito,
    anio, setAnio, secciones, setSecciones, mostrarOpcionesUsuario, setMostrarOpcionesUsuario, modalAgregarUsuario, setModalAgregarUsuario,
    obtenerUsuarios, usuarios, modalAgregarSimpatizante, setModalAgregarSimpatizante, mostrarOpcionesSimpatizantes, setMostrarOpcionesSimpatizantes, 
    mostrarOpcionesComite, setMostrarOpcionesComite, filtroIntegrantes, setFiltroIntegrantes, mostrarOpcionesMilitantes, setMostrarOpcionesMilitantes,
    modalAgregarMilitante, setModalAgregarMilitante, opcionesCapas1, setOpcionesCapas1, mostrarDatosCapa,
    setMostrarDatosCapa, idMunicipioSeleccionado, setIdMunicipioSeleccionado, setSeccionSeleccionada, seccionSeleccionada, titulo, setTitulo,
    datosPartido, setDatosPartido, estadoModalFotografiaMilitante, setEstadoModalFotografiaMilitante,
    idMilitanteSeleccionado, setIdMilitanteSeleccionado, integranteSeleccionado, setIntegranteSeleccionado,
    idSimpatizanteEdicion, setIdSimpatizanteEdicion, modalAgregaOpinion,
    setModalAgregaOpinion, aplicarFiltro, setAplicarFiltro, modalCambiaPassword, setModalCambiaPassword, idDependenciaMostrar, setIdDependenciaMostrar
  }}>
    {props.children}
  </AuthContext.Provider>
  )
}

