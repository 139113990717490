import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
export const CiudadanosPage = ({}) => {
    const [ciudadanos, setCiudadanos] = useState([]);

    useEffect(() => {
        obtenerCiudadanos();
    }, [])
    

    const obtenerCiudadanos = () => {
        fetch(process.env.REACT_APP_API_URL+'/api.reportesp.php?funcion=ciudadanos')
        .then(response => response.json())
        .then(arrCiudadanos => {
            setCiudadanos(arrCiudadanos)
          //console.log(reportes.length)
        })
        .catch(error => {
          console.log('Ocurrio un error', error)
        })
    }

  return (
    <div
        style={{
            display:'flex',
            //flex:1,
            margin:10,
            marginRight:20,
            marginTop:20,
            backgroundColor:'white',
            borderRadius:10
        }}
    >
        <TableContainer
            
            style={{
                display:'flex',
                flex:1,
                maxHeight:'70vh'
                
            }}
        >
            <Table aria-aria-label='a dense table' stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>Reportes</TableCell>
                        <TableCell>Cruzado</TableCell>
                        <TableCell>Nombre</TableCell>
                        <TableCell>Dirección</TableCell>
                        <TableCell>Teléfono</TableCell>
                        <TableCell>Email</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody
                    style={{
                        fontSize:'.7rem'
                    }}
                >
                {
                    ciudadanos.map(ciudadano => (
                        <TableRow
                            style={{maxHeight:30}}
                        >
                            <TableCell style={{maxHeight:30}}>
                               {ciudadano.Reportes}
                            </TableCell>
                            <TableCell>
                                No
                            </TableCell>
                            <TableCell>
                                {ciudadano.ProfileName}
                            </TableCell>
                            <TableCell>
                                
                            </TableCell>
                            <TableCell>
                               {ciudadano.Telefono}
                            </TableCell>
                            <TableCell>
                                
                            </TableCell>
                        </TableRow>
                    ))
                }
                </TableBody>
            </Table>
        </TableContainer>
    </div>
  )
}
