import { Button, Checkbox, FilledInput, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from '@mui/material'
import { borderRadius } from '@mui/system'
import React, { useContext, useState } from 'react'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { AuthContext } from '../context/AuthContext';
import logo from "../assets/logoblanco.png"
export const LoginPage = () => {
    const [usuario, setUsuario] = useState();
    const [password, setPassword] = useState();
    const {onLogin} = useContext(AuthContext);
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    const login =() => {
        onLogin(usuario,password);
    }
  
  return (
    <div
        style={{
            display:'flex',
            flexDirection:'column',
            flex:1,
            backgroundColor:'#642644',
            height:'100vh',
            justifyContent:'center'
        }}
    >
        <div
            style={{
                display:'flex',
                flexDirection:'row',
                alignItems:'center',
                justifyContent:'center'
            }}
        >
            <div
                style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:1,
                    marginLeft:100,
                    marginRight:100,
                    backgroundColor:'transparent',
                    justifyContent:'center',
                    padding:100,
                    borderRadius:20
                }}
            >
                    <div
                        style={{
                            display:'flex',
                            flexDirection:'column',
                            backgroundColor:'white',
                            borderRadius:20,
                            maxWidth:'80%',
                            paddingTop:50,
                            paddingBottom:50
                        }}
                    >
                        <div
                            style={{
                                display:'flex',
                                flexDirection:'row',
                                flex:1,
                                justifyContent:'center',
                                backgroundColor:'transparent',
                                //marginTop:60
                            }}
                        >
                            <Typography
                                style={{
                                    fontSize:18,
                                    color:'#642644',
                                    fontWeight:'600'
                                }}
                            >
                                Acceder al Panel
                            </Typography>
                        </div>
                        <div
                            style={{
                                display:'flex',
                                flexDirection:'row',
                                justifyContent:'center'
                            }}
                        >
                            <Typography
                                style={{
                                    fontSize:12
                                }}
                            >
                            Usa tú correo y contraseña proporcionados
                            </Typography>
                        </div>
                        <div
                            style={{
                                display:'flex',
                                flexDirection:'row',
                                justifyContent:'center',
                                marginTop:30
                            }}
                        >
                            <TextField 
                                onChange={(e) => setUsuario(e.target.value)}
                                style={{
                                    display:'flex',
                                    flex:1,
                                    maxWidth:'60%',
                                    borderRadius:20
                                }}
                                inputProps={{
                                    style:{
                                        borderRadius:10
                                    }
                                }}
                                size='small' label="Correo elctrónico" variant="filled" />
                        </div>
                        <div
                            style={{
                                display:'flex',
                                flexDirection:'row',
                                justifyContent:'center',
                                marginTop:30
                            }}
                        >
                            <FormControl
                                style={{
                                    display:'flex',
                                    flex:1,
                                    maxWidth:'60%'

                                }}
                                sx={{ m: 1, width: '25ch' }} variant="filled">
                            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                            <FilledInput
                                onChange={(e) => setPassword(e.target.value)}
                                id="outlined-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                                }
                                label="Password"
                            />
                            </FormControl>
                        </div>
                        <div
                            style={{
                                display:'flex',
                                flexDirection:'row',
                                justifyContent:'center',
                                marginTop:5,
                            }}
                        >
                             <FormControlLabel control={<Checkbox size='small'  />} label={<Typography style={{fontSize:12}}>Mantener sesión</Typography>} />
                             <Button
                             
                                style={{
                                    textTransform:'unset',
                                    color:'#642644',
                                }}
                             >¿Olvidaste tu contraseña?</Button>
                        </div>
                        <div
                            style={{
                                display:'flex',
                                flexDirection:'row',
                                justifyContent:'center',
                                marginTop:5,
                            }}
                        >
                             <Button
                             onClick={() => login()}
                                style={{
                                    textTransform:'unset',
                                    backgroundColor:'#642644',
                                    color:'white',
                                    minWidth:'60%',
                                    borderRadius:10,
                                    marginTop:20
                                }}
                             >
                                Iniciar sesión
                             </Button>
                        </div>
                    </div>
            </div>
            <div
                style={{
                    display:'flex',
                    flexDirection:'column',
                    flex:1,
                    marginLeft:50,
                    marginRight:50,
                    backgroundColor:'transparent',
                    justifyContent:'flex-start',
                    alignItems:'flex-start',
                    width:'100%',
                    height:'100%',
                    paddingTop:200
                }}
            >
                             <img  
                src={require("../assets/logoblanco.png") } 
                style={{
                 // width:150,
                //  height:150
                }}
              />
            </div>
        </div>
    </div>
  )
}
