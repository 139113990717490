import { Card, CardContent, CardHeader, Dialog, DialogContent, DialogTitle, Divider, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { ReportesContext } from '../pages/ReportesPage'

export const ModalReporte = () => {
    const {modalReporte, setModalReporte, reporteSeleccionado} = useContext(ReportesContext);
    console.log('reporte seleccionado', reporteSeleccionado)

    const handleClose = () => {
        setModalReporte(false)
    }
  return (
    <Dialog onClose={handleClose} fullWidth maxWidth='xl' open={modalReporte}
        style={{
            backgroundcolor:'#f3f3f3'
        }}
    >
        <DialogTitle>
            Reporte
        </DialogTitle>
        <Divider />
        <DialogContent>
        <div
            style={{
                display:'flex',
                flexDirection:'row',
            }}
        >
            <img onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src="";
            }} style={{width:'auto', height:'70vh',maxWidth:'60%', borderRadius:10, position:'relative', margin:30}} src={'http://quejas.juarez.gob.mx/imagenes/Reporte-'+reporteSeleccionado?.IdReporte.padStart(6,"0")+'.jpg'} />
            {
                reporteSeleccionado?.IdEstatus=='3' ? (
            <img onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src="";
            }} style={{width:'auto', height:'70vh',maxWidth:'60%', borderRadius:10, position:'relative', margin:30}} src={'http://quejas.juarez.gob.mx/imagenes/Tecnico-'+reporteSeleccionado?.IdReporte.padStart(6,"0")+'.jpg'} />
                ):null
            }
            <Card
                style={{margin:20,
                    backgroundColor:'white'
                }}
            >
                <CardContent>
                    <div
                        style={{
                            display:'flex',
                            flexDirection:'column'
                        }}
                    >
                        <Typography style={{fontSize:16, fontWeight:'600'}}>
                            Folio #{reporteSeleccionado?.IdReporte} - {reporteSeleccionado?.Enviado}
                        </Typography>
                        <Typography style={{fontSize:16, fontWeight:'600'}}>
                            {reporteSeleccionado?.Dependencia}
                        </Typography>
                        <Typography style={{fontSize:14, fontWeight:'400'}}>
                            {reporteSeleccionado?.TipoReporte}
                        </Typography>
                        <Typography style={{fontSize:14, fontWeight:'400'}}>
                            {reporteSeleccionado?.DireccionReporte}
                        </Typography>
                        <Typography style={{fontSize:14, fontWeight:'400'}}>
                            {reporteSeleccionado?.ProfileName  ? reporteSeleccionado?.ProfileName : reporteSeleccionado?.Usuarios_Nombre ? reporteSeleccionado?.Usuarios_Nombre+' '+reporteSeleccionado?.Usuarios_ApellidoPaterno:null}
                        </Typography>
                        <Typography style={{fontSize:14, fontWeight:'400'}}>
                            {reporteSeleccionado?.Telefono  ? reporteSeleccionado?.Telefono : null}
                        </Typography>
                        <Typography style={{fontSize:14, fontWeight:'400'}}>
                            Plataforma - {reporteSeleccionado?.Plataforma}
                        </Typography>
                        <Typography style={{fontSize:14, fontWeight:'400'}}>
                            Telefono - {reporteSeleccionado?.telefono}
                        </Typography>
                        <Divider />
                        {
                            reporteSeleccionado?.IdPlataforma==3 ? (
                        <Typography style={{fontSize:14, fontWeight:'400', marginTop:20}}>
                            {reporteSeleccionado?.Descripcion}
                        </Typography>
                            ):null
                        }
                        {
                reporteSeleccionado?.IdEstatus=='3' ? (
                    <>
                        <Typography style={{fontSize:16, fontWeight:'600', marginTop:20}}>
                            Reporte Finalizado
                        </Typography>
                        <Typography style={{fontSize:14, fontWeight:'600', marginTop:0}}>
                            {reporteSeleccionado?.Resuelto}
                        </Typography>
                        <Typography style={{fontSize:14, fontWeight:'400', marginTop:5}}>
                            {reporteSeleccionado?.Comentarios}
                        </Typography>
                    </>
                ):null
                    }
                    </div>
                </CardContent>
            </Card>
        </div>
        </DialogContent>
    </Dialog>
  )
}
