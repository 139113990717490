import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { EliminarSector } from '../helpers/SectoresHelper';
import { SectoresContext } from '../pages/SectoresPage';

export const ModalEliminaSector = () => {
    const {tramiteSeleccionado, obtenerTramites, modalEliminaSector, setModalEliminaSector, sectorElimina, obtenerSectores} = React.useContext(SectoresContext);
  const handleClose = () => {
    setModalEliminaSector(false);
  };

  const eliminarSector = () => {
    EliminarSector(sectorElimina.idSector).then(respuesta => {
//        obtenerTramites();
        setModalEliminaSector(false)
        obtenerSectores();

    });
  }

  return (
    <div>
      <Dialog
        open={modalEliminaSector}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Eliminar sector 
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Está seguro(a) de eliminar el sector "{sectorElimina?.descripcion}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{color:'red'}} onClick={() => eliminarSector()}>Eliminar</Button>
          <Button onClick={handleClose} autoFocus>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}