import { Divider, Grow } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { ObtenerServicios } from '../helpers/ServiciosHelper';
import { ServicioComponent } from './ServicioComponent';

export const TablaServiciosComponent = () => {
  const [servicios, setServicios] = useState([]);

  const obtenerServicios = () => {
    ObtenerServicios().then(datos=>setServicios(datos));
  }

  useEffect(() => {
    obtenerServicios();
  }, [])
  

  return (
    <div
    style={{
      display:'flex',
      flexDirection:'column',
      flex:1,
        color:'black',
        marginTop:10,
        marginLeft:40,
        marginRight:20
    }}
>
  <div
    style={{
      display:'flex',
      flexDirection:'row',
      flex:1,
      alignItems:'center',
      maxHeight:70,
      justifyContent:'space-between'
    }}
  >
    <div

    >
      <span
        style={{
          fontSize:40,
          fontWeight:'bold'
        }}
      >
        Servicios
      </span>
    </div>
  </div>
  <div
    style={{
      display:'flex',
      flexDirection:'row',
      fontWeight:500,
      fontSize:13,
      backgroundColor:'white',
      padding:10
    }}
  >
    <div
      style={{
        display:'flex',
        flex:5
      }}
    >
      Id
    </div>
    <div
      style={{
        display:'flex',
        flex:30
      }}
    >
      Descripción
    </div>
    <div
      style={{
        display:'flex',
        flex:10
      }}
    >
      Color
    </div>
    <div
      style={{
        display:'flex',
        flex:10
      }}
    >
      Icono
    </div>
    <div
      style={{
        display:'flex',
        flex:20
      }}
    >
      Origen
    </div>
    <div
      style={{
        display:'flex',
        flex:10
      }}
    >
      Orden
    </div>

  </div>
  <div
    style={{
      display:'flex',
      flexDirection:'column'
    }}
  >
    {
      servicios?.map(servicio => (
        <>
          <ServicioComponent servicio={servicio} />
          <Divider />
        </>
      ))
    }
  </div>
  </div>
  )
}
